import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // jwt-decode 임포트

import { useNavigate } from "react-router-dom"; // useNavigate를 import합니다.
import "./login.scss";

function Login({ loginUser, setLoginUser }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // 로그인 상태 유지 체크박스 상태

  // 이제 setRole 함수를 props로 전달할 수 있습니다.
  const handleLogin = async () => {
    try {
      const response = await axios.post("/api/user/login", {
        username,
        password,
      });

      const { token } = response.data;

      if (rememberMe) {
        localStorage.setItem("token", token); // 체크박스가 선택되었을 때만 토큰 저장
      } else {
        sessionStorage.setItem("token", token); // 선택되지 않았을 때는 sessionStorage에 저장
      }
      try {
        const decoded = jwtDecode(token); // 토큰 해독
        if (decoded?.name) {
          setLoginUser((prevLoginUser) => ({
            ...prevLoginUser,
            name: decoded.name,
          }));
        }
        if (decoded?.role) {
          // role만 업데이트하고자 할 때
          setLoginUser((prevLoginUser) => ({
            ...prevLoginUser,
            role: decoded.role,
          }));
        }
        if (decoded?.hospital_name_id) {
          // role만 업데이트하고자 할 때
          setLoginUser((prevLoginUser) => ({
            ...prevLoginUser,
            hospital_name_id: decoded.hospital_name_id,
          }));
        }
        // 리디렉션 로직: role에 따라 다른 경로로 이동
        if ([3, 4, 5].includes(decoded.role)) {
          navigate("/tm"); // role이 3, 4, 5일 경우 /tm으로 리디렉트
        } else {
          navigate("/db"); // 그 외의 경우 /db로 리디렉트
        }
        console.log(decoded.role, decoded.hospital_name_id);
      } catch (error) {
        console.error("토큰 해독 오류:", error);
      }
    } catch (error) {
      console.error("로그인 실패:", error.response?.data?.error || "서버 오류");
      alert("로그인 실패: " + (error.response?.data?.error || "서버 오류"));
    }
  };

  return (
    <div className="login-container">
      <h2>
        <img src="/images/logo.png" alt="퍼널스" />
      </h2>
      <input
        type="text"
        id="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
        placeholder="아이디"
      />
      <input
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        placeholder="비밀번호"
      />

      <label className="remember_me">
        <input
          type="checkbox"
          id="rememberMe"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)} // 체크박스 상태 변경
        />
        로그인 상태 유지
      </label>

      <button onClick={handleLogin}>로그인</button>

      <p className="copyright">
        Copyright © Funnel Solution All Rights Reserved.
      </p>
    </div>
  );
}

export default Login;
