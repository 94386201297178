import React, { useState, useEffect } from "react";
import "./timePicker.scss";
const TimePicker = ({ currentTime, field, onTimeChange }) => {
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isAM, setIsAM] = useState(true);

  // 처음 시간을 저장할 변수들
  const [initialHour, setInitialHour] = useState(null);
  const [initialMinute, setInitialMinute] = useState(null);

  const plusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
    >
      <path
        d="M1.33301 10.381L9.99967 1.71436L18.6663 10.381"
        stroke="#4880FF"
        stroke-width="2"
      />
    </svg>
  );

  const minusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
    >
      <path
        d="M18.667 1.66659L10.0003 10.3333L1.33366 1.66659"
        stroke="#4880FF"
        stroke-width="2"
      />
    </svg>
  );

  useEffect(() => {
    if (!currentTime || !currentTime.includes(":")) return;
    const [initialHourValue, initialMinuteValue] = currentTime
      .split(":")
      .map(Number);
    setHour(initialHourValue);
    setMinute(initialMinuteValue);
    setIsAM(initialHourValue < 12);

    // 초기 시간 설정
    setInitialHour(initialHourValue);
    setInitialMinute(initialMinuteValue);
  }, [currentTime]);

  useEffect(() => {
    if (initialHour !== null && initialMinute !== null) {
      if (hour !== initialHour || minute !== initialMinute) {
        handleTimeChange();
      }
    }
  }, [hour, minute, initialHour, initialMinute]);

  useEffect(() => {
    if (hour != null) {
      console.log("Updated Hour:", hour);
      setIsAM(hour < 12);
    }
  }, [hour]);

  const handleTimeChange = () => {
    const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    // 시간과 분이 바뀌었을 때만 업데이트
    if (hour !== initialHour || minute !== initialMinute) {
      onTimeChange(field, formattedTime);
    }
    console.log("formattedTime", formattedTime);
  };

  const increaseHour = () => {
    setHour((prev) => (prev === 23 ? 0 : prev + 1));
  };

  const decreaseHour = () => {
    setHour((prev) => (prev === 0 ? 23 : prev - 1));
  };

  const increaseMinute = () => {
    setMinute((prev) => (prev === 59 ? 0 : prev + 1));
  };

  const decreaseMinute = () => {
    setMinute((prev) => (prev === 0 ? 59 : prev - 1));
  };

  const toggleAMPM = () => {
    setIsAM((prevIsAM) => !prevIsAM);
  };

  const handleInputChange = (e, type) => {
    let value = e.target.value.replace(/^0+(?=\d)/, ""); // 앞의 0 제거
    value = value.slice(0, 2); // 최대 2자리까지만 입력 가능
    if (isNaN(value) || value < 0) value = 0;
    if (type === "hour" && value > 23) value = 23;
    if (type === "minute" && value > 59) value = 59;
    type === "hour" ? setHour(value) : setMinute(value);
  };

  return (
    <div className="time-picker">
      <div>
        <div className="time-unit">
          <button onClick={decreaseHour}>{plusIcon}</button>
          <input
            type="number"
            value={hour}
            min="0"
            max="23"
            onChange={(e) => handleInputChange(e, "hour")}
          />
          <button onClick={increaseHour}>{minusIcon}</button>
        </div>

        <span>:</span>
        <div className="time-unit">
          <button onClick={decreaseMinute}>{plusIcon}</button>
          <input
            type="number"
            value={minute}
            min="0"
            max="59"
            onChange={(e) => handleInputChange(e, "minute")}
          />
          <button onClick={increaseMinute}>{minusIcon}</button>
        </div>
        <div className="ampm">
          {/* <button onClick={toggleAMPM}>{plusIcon}</button> */}
          <span>{isAM ? "AM" : "PM"}</span>
          {/* <button onClick={toggleAMPM}>{minusIcon}</button> */}
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
