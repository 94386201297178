import React, { useState, useEffect } from "react";
import axios from "axios";
import TitleBox from "../../components/TitleBox";
import CustomDropdown from "../admin/listsetting/CustomDropdown";
import "./codeGenerator.scss";
import AlertModal from "../../components/AlertModal";
const CodeGenerator = () => {
  const [codes, setCodes] = useState([]);
  const [formData, setFormData] = useState({
    ad_title: "",
    ad_number: "",
    hospital_name_id: "",
    event_name_id: [],
    advertising_company_id: "",
    url_code: "",
    search_title: "", // 광고제목 검색 필터
    search_hospital_name_id: "", // 병원이름 검색 필터
  });

  const [updatedFields, setUpdatedFields] = useState({}); // 수정된 필드를 추적하는 상태
  const [isFormValid, setIsFormValid] = useState(false);
  const [editingCodeId, setEditingCodeId] = useState(null);
  const [hospitals, setHospitals] = useState([]); // 병원 목록 상태
  const [events, setEvents] = useState([]); // 이벤트 목록 상태
  const [companies, setCompanies] = useState([]); // 매체 목록 상태
  const [selectedRows, setSelectedRows] = useState([]); // 선택된 행 상태
  const itemsPerPage = 10; // 한 페이지에 표시할 항목 수

  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수

  //모달
  const [modals, setModals] = useState({
    delete: false,
    confirmAction: false,
    warnUser: false,
  });
  const toggleModal = (modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: !prev[modalName] }));
  };

  // 병원, 이벤트, 매체 목록 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [hospitalRes, eventRes, companyRes] = await Promise.all([
          axios.get("/api/list/hospitals"),
          axios.get("/api/list/events"),
          axios.get("/api/list/advertising_companies"),
        ]);

        // API로 받은 데이터를 드롭다운의 options 형식으로 변환
        const hospitalOptions = hospitalRes.data.items.map((hospital) => ({
          value: hospital.id,
          label: hospital.name,
        }));
        const eventOptions = eventRes.data.items.map((event) => ({
          value: event.id,
          label: event.name,
        }));
        const companyOptions = companyRes.data.items.map((company) => ({
          value: company.id,
          label: company.name,
        }));

        // 상태 업데이트
        setHospitals(hospitalOptions);
        setEvents(eventOptions);
        setCompanies(companyOptions);
      } catch (error) {
        console.error("데이터를 불러오는 중 오류가 발생했습니다:", error);
      }
    };

    fetchData();
  }, []);
  // 코드 데이터 가져오기
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`/api/urlCode/list`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search_title: formData.search_title || "", // 광고제목 필터 추가
          search_hospital_name_id: formData.search_hospital_name_id || "", // 병원이름 필터 추가
        },
      });
      setCodes(response.data.codesWithDetails);
      setTotalPages(response.data.totalPages); // 서버에서 받은 전체 페이지 수
      setCurrentPage(response.data.currentPage); // 서버에서 받은 현재 페이지
    } catch (error) {
      console.error("데이터 로딩 오류:", error);
      alert("데이터를 불러오는데 실패했습니다.");
      setCodes([]);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  // Input change handler
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const handleDropdownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
    console.log(selectedRows);
  };

  // 코드 생성
  const handleSubmit = async () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    const dataToSubmit = { ...formData, url_code: result };

    try {
      const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기
      await axios.post("/api/urlcode/new", dataToSubmit, {
        headers: {
          Authorization: `Bearer ${token}`, // 요청 헤더에 토큰 추가
        },
      });
      alert("코드 생성했습니다");
      setSelectedRows([]); // 생성 후 선택된 항목 초기화
      setFormData({
        ad_title: "",
        ad_number: "",
        hospital_name_id: "",
        event_name_id: [],
        advertising_company_id: "",
        url_code: "",
        search_title: "", // 광고제목 검색 필터
        search_hospital_name_id: "", // 병원이름 검색 필터
      });
      console.log(formData);
      setCurrentPage(1);
      fetchUsers(); // 생성 후 다시 데이터 가져오기
    } catch (error) {
      console.error("Error posting data:", error);
      if (error.response && error.response.status === 400) {
        alert("중복된 코드입니다. 다시 생성해 주세요.");
      } else {
        alert("데이터 전송 중 오류가 발생했습니다.");
      }
    }
  };

  // 유저 수정 버튼 핸들러
  const handleEdit = (id) => {
    setEditingCodeId(id);
  };

  // 개별 유저 필드 수정 핸들러
  const handleCodeChange = (id, field, value) => {
    setCodes((prevCodes) =>
      prevCodes.map((code) =>
        code.id === id
          ? {
              ...code,
              [field]: field === "event_name_id" ? value.join(",") : value, // 배열 → 문자열 변환
            }
          : code
      )
    );
  };

  // 유저 수정 저장 핸들러
  const handleEditSave = async (editingCodeId) => {
    const codeToUpdate = codes.find((code) => code.id === editingCodeId);

    const updatedFields = {
      ad_title: codeToUpdate.ad_title,
      ad_number: codeToUpdate.ad_number,
      event_name_id: codeToUpdate.event_name_id,
      hospital_name_id: codeToUpdate.hospital_name_id,
      advertising_company_id: codeToUpdate.advertising_company_id,
    };

    console.log("???", codeToUpdate);
    try {
      // 업데이트할 필드만 포함한 객체 전송
      await axios.put(`/api/urlcode/update/${editingCodeId}`, updatedFields);
      alert("코드 수정 완료");
      setEditingCodeId(null);
      fetchUsers(); // 업데이트 후 코드 리스트 다시 가져오기
    } catch (error) {
      console.error("유저 정보 수정 중 오류가 발생했습니다:", error);
      alert("유저 정보 수정에 실패했습니다.");
    }
  };

  // 페이지 네이션
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // 페이지 변경
    }
  };
  // 체크박스 선택 삭제
  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert("삭제할 항목을 선택해 주세요.");
      return;
    }

    try {
      await Promise.all(
        selectedRows.map((id) => axios.delete(`/api/urlcode/delete/${id}`))
      );
      // alert("선택한 항목을 삭제했습니다.");
      setSelectedRows([]); // 삭제 후 선택된 항목 초기화
      fetchUsers(); // 삭제 후 다시 데이터 가져오기
    } catch (error) {
      console.error("삭제 오류:", error);
      alert("삭제하는 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="code-generator container">
      <TitleBox mainmenu="코드 생성" />
      <div className="code_form">
        <div>
          <div className="input-group">
            <input
              type="text"
              name="ad_title"
              value={formData.ad_title}
              onChange={handleChange}
              placeholder="광고제목"
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              name="ad_number"
              value={formData.ad_number}
              onChange={handleChange}
              placeholder="랜딩번호"
            />
          </div>
          <div className="input-group">
            <CustomDropdown
              key={formData.hospital_name_id}
              options={hospitals} // 병원 목록 전달
              selectedValue={formData.hospital_name_id}
              onChange={(value) =>
                handleDropdownChange("hospital_name_id", value)
              } // 선택된 값 업데이트
              bigDrop={1}
              search={1}
            />
          </div>
          {/* 코드 생성 시 이벤트명 선택  */}
          <div className="input-group">
            <CustomDropdown
              key={formData.event_name_id}
              options={events} // 이벤트 목록 전달
              selectedValue={formData.event_name_id}
              onChange={(value) => handleDropdownChange("event_name_id", value)} // 선택된 값 업데이트
              bigDrop={1}
              search={1}
              // 옵션 복수로 인한 프롭스
              optionChecked={true}
              ischeckedopen={true}
            />
          </div>
          <div className="input-group">
            <CustomDropdown
              key={formData.advertising_company_id}
              options={companies} // 매체 목록 전달
              selectedValue={formData.advertising_company_id}
              onChange={(value) =>
                handleDropdownChange("advertising_company_id", value)
              } // 선택된 값 업데이트
              bigDrop={1}
              search={1}
            />
          </div>
        </div>
        <button
          className="codegeneratorBtn"
          type="button"
          onClick={handleSubmit}
        >
          코드 생성하기
        </button>
      </div>

      <div className="code-table-header">
        <h2 className="table_title">코드 리스트</h2>
        <div className="search-group">
          <input
            type="text"
            name="search_title"
            className="search_title"
            value={formData.search_title || ""}
            onChange={handleChange}
            placeholder="광고제목 검색"
          />
          <CustomDropdown
            options={hospitals}
            selectedValue={formData.search_hospital_name_id || ""}
            onChange={(value) =>
              handleDropdownChange("search_hospital_name_id", value)
            }
            bigDrop={1}
            search={1}
          />
          <button type="button" className="search_btn" onClick={fetchUsers}>
            검색
          </button>
          <button
            className="table_delete_btn"
            onClick={() => toggleModal("delete")}
          >
            삭제
          </button>
          {modals.delete && (
            <AlertModal
              message="현재 선택한 코드를 정말 삭제하시겠습니까?"
              onConfirm={() => {
                handleDelete(); // 삭제 로직 호출
                toggleModal("delete"); // 모달 닫기
              }}
              onCancel={() => {
                toggleModal("delete"); // 모달 닫기
              }}
            />
          )}
        </div>
      </div>
      <table className="code-table">
        <thead>
          <tr>
            <th style={{ width: "8%" }}>선택</th>
            <th style={{ width: "calc((100% - 8% * 2) / 8)" }}>랜딩코드</th>
            <th style={{ width: "calc((100% - 8% * 2) / 7)" }}>랜딩번호</th>
            <th style={{ width: "calc((100% - 8% * 2) / 7)" }}>병원명</th>
            <th style={{ width: "calc((100% - 8% * 2) / 4)" }}>광고제목</th>
            <th style={{ width: "calc((100% - 8% * 2) / 7)" }}>매체</th>
            <th style={{ width: "calc((100% - 8% * 2) / 6)" }}>이벤트명</th>
            <th style={{ width: "8%" }}>수정</th>
          </tr>
        </thead>
        <tbody>
          {codes &&
            codes.map((code) => (
              <tr
                key={code.id}
                className={selectedRows.includes(code.id) ? "selected" : ""}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(code.id)}
                    onChange={() => handleCheckboxChange(code.id)}
                  />
                </td>
                <td>{code.url_code}</td>
                <td>
                  <input
                    type="text"
                    value={code.ad_number}
                    disabled={editingCodeId !== code.id} // 수정 모드일 때만 활성화
                    onChange={(e) =>
                      handleCodeChange(code.id, "ad_number", e.target.value)
                    }
                  />
                </td>

                <td>
                  {editingCodeId === code.id ? (
                    <CustomDropdown
                      options={hospitals} // 병원 목록 전달
                      selectedValue={code.hospital_name_id}
                      onChange={
                        (value) =>
                          handleCodeChange(code.id, "hospital_name_id", value) // 선택된 값 업데이트
                      }
                      // bigDrop={2}
                      search={1}
                    />
                  ) : (
                    <input
                      type="text"
                      value={code.hospital_name}
                      disabled // 수정 모드가 아닐 때는 비활성화
                      readOnly // 비활성화 상태에서 읽기 전용
                    />
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={code.ad_title}
                    disabled={editingCodeId !== code.id} // 수정 모드일 때만 활성화
                    onChange={(e) =>
                      handleCodeChange(code.id, "ad_title", e.target.value)
                    }
                    style={{ width: "80%" }}
                  />
                </td>
                <td>
                  {editingCodeId === code.id ? (
                    <CustomDropdown
                      options={companies} // 매체 목록 전달
                      selectedValue={code.advertising_company_id}
                      onChange={
                        (value) =>
                          handleCodeChange(
                            code.id,
                            "advertising_company_id",
                            value
                          ) // 선택된 값 업데이트
                      }
                      // bigDrop={1}
                      search={1}
                    />
                  ) : (
                    <input
                      type="text"
                      value={code.advertising_company_name}
                      disabled // 수정 모드가 아닐 때는 비활성화
                      readOnly // 비활성화 상태에서 읽기 전용
                    />
                  )}
                </td>
                <td>
                  <div>
                    {editingCodeId === code.id ? (
                      <CustomDropdown
                        options={events} // 이벤트 목록 전달
                        selectedValue={code.event_name_id?.split(",")} // 문자열 → 배열 변환
                        onChange={
                          (value) =>
                            handleCodeChange(code.id, "event_name_id", value) // 선택된 값 업데이트
                        }
                        // bigDrop={1}
                        search={1}
                        optionChecked={true}
                      />
                    ) : (
                      <input
                        type="text"
                        value={code.event_names}
                        disabled // 수정 모드가 아닐 때는 비활성화
                        readOnly // 비활성화 상태에서 읽기 전용
                      />
                    )}
                  </div>
                </td>

                <td>
                  {editingCodeId === code.id ? (
                    <button onClick={() => handleEditSave(code.id)}>
                      저장
                    </button>
                  ) : (
                    <button onClick={() => handleEdit(code.id)}>수정</button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
};

export default CodeGenerator;
