// DateFilter.jsx
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "../customorDataPage/DateRangePicker";
import { shiftDateRange } from "../../utils/shiftdaterange";
import axios from "axios";

function DateFilter({
  customDateRange,
  setCustomDateRange,
  selectedDateOption,
  setSelectedDateOption,
  datePickerStatus,
  setDatePickerStatus,
  onFilterChange,
  filters,
}) {
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // 🔹 드롭다운 요소 참조

  const dateOptions = [
    { label: "오늘", value: "today" },
    { label: "최근 7일", value: "last7days" },
    { label: "최근 30일", value: "last30days" },
    { label: "어제", value: "yesterday" },
    { label: "지난주 (오늘 제외)", value: "lastweek" },
    { label: "전체기간", value: "all" }, // ✅ 전체 기간 추가
  ];

  // **외부 클릭 감지 핸들러**
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDateDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatKoreanDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Asia/Seoul",
    };
    return new Intl.DateTimeFormat("ko-KR", options).format(date);
  };

  const handleDateDropdownClick = () => {
    setIsDateDropdownOpen(!isDateDropdownOpen);
    setDatePickerStatus(false);
  };

  const handleDateOptionClick = async (option, value) => {
    setSelectedDateOption(option);
    setIsDateDropdownOpen(false);

    await updateDateRange(value); // 여기서 updateDateRange 실행
  };

  const updateDateRange = async (option) => {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (option) {
      case "today":
        startDate = endDate = new Date();
        break;
      case "yesterday":
        startDate = endDate = new Date(today.setDate(today.getDate() - 1));
        break;
      case "last7days":
        startDate = new Date(today.setDate(today.getDate() - 6));
        endDate = new Date();
        break;
      case "last30days":
        startDate = new Date(today.setDate(today.getDate() - 29));
        endDate = new Date();
        break;
      case "lastweek":
        const lastWeekEnd = new Date(today);
        lastWeekEnd.setDate(lastWeekEnd.getDate() - lastWeekEnd.getDay()); // 지난주 끝 (일요일)
        const lastWeekStart = new Date(lastWeekEnd);
        lastWeekStart.setDate(lastWeekEnd.getDate() - 6); // 지난주 시작 (월요일)

        startDate = lastWeekStart;
        endDate = lastWeekEnd;
        break;
      case "all": // ✅ 전체 기간 선택 시 API 호출
        try {
          const response = await axios.get("/api/adexpense/getMinMaxDates");
          const { minDate, maxDate } = response.data;
          startDate = new Date(minDate);
          endDate = new Date(maxDate);
        } catch (error) {
          console.error("전체 기간 날짜 가져오기 오류:", error);
          return; // 오류 발생 시 state 업데이트 방지
        }
        break;
      default:
        startDate = endDate = new Date();
        break;
    }

    setCustomDateRange({
      startDate: startDate,
      endDate: endDate,
    });

    // onFilterChange({
    //   ...filters,
    //   startDate: startDate,
    //   endDate: endDate,
    // });
  };

  const DatePickerHandler = () => {
    setDatePickerStatus(!datePickerStatus);
    setIsDateDropdownOpen(false);
  };

  const shiftDateRangeHandler = (direction) => {
    const { startDate, endDate } = shiftDateRange(
      customDateRange.startDate,
      customDateRange.endDate,
      direction
    );
    setCustomDateRange({
      startDate,
      endDate,
    });
    setIsDateDropdownOpen(false);
    setDatePickerStatus(false);
  };

  return (
    <div className="date-filter-container" ref={dropdownRef}>
      소진날짜 선택
      <div className="date-filter-box">
        <div className="date-filter">
          <div className="custom-select" onClick={handleDateDropdownClick}>
            <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
            <span>{selectedDateOption}</span>
          </div>
          <span className="lineSeper">|</span>
          {isDateDropdownOpen && (
            <ul className="custom-options">
              {dateOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() =>
                    handleDateOptionClick(option.label, option.value)
                  }
                  className={`option-item ${
                    selectedDateOption === option.label ? "selected" : ""
                  }`}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* 날짜 표시 및 화살표 */}
        <div className="date-display">
          <span className="date-text" onClick={DatePickerHandler}>
            {formatKoreanDate(customDateRange.startDate)} ~
            {formatKoreanDate(customDateRange.endDate)}
          </span>
          <div className="divider large"></div>
          <div className="arrow-group">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="arrow-icon"
              onClick={() => shiftDateRangeHandler("prev")}
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              className="arrow-icon"
              onClick={() => shiftDateRangeHandler("next")}
            />
          </div>
        </div>
      </div>
      <div className="dateRangePicker-box">
        {datePickerStatus && (
          <DateRangePicker
            customDateRange={customDateRange}
            setCustomDateRange={setCustomDateRange}
            closePicker={() => {
              setSelectedDateOption("사용자 지정");
              setDatePickerStatus(false);
              setIsDateDropdownOpen(false);
            }}
            setSelectedDateOption={setSelectedDateOption}
          />
        )}
      </div>
    </div>
  );
}

export default DateFilter;
