import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { jwtDecode } from "jwt-decode"; // jwt-decode 임포트
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "../../customorDataPage/DateRangePicker";
import Dropdown from "./Dropdown";
import CustomDropdown from "../../admin/listsetting/CustomDropdown";
import { shiftDateRange } from "../../../utils/shiftdaterange";

const Filters = ({
  hospitalList,
  setHospitalList,
  selectedHospital,
  setSelectedHospital,
  selectedManager,
  setSelectedManager,
  selectedDate,
  setSelectedDate,
  managerList,
  setManagerList,
  role,
  loginUser,
  handleFilterChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // 드롭다운 상태 관리
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태

  // 병원 목록 가져오기
  const fetchHospitals = async () => {
    if (hospitalList.length === 0) {
      setIsLoading(true);
      try {
        const response = await axios.get("/api/tm-workstatus/filter/hospital");
        if (response.data) {
          const formattedOptions = response.data.map((hospital) => ({
            value: hospital.id, // 'id'를 'value'로 매핑
            label: hospital.name || "null", // 'public_name'을 'label'로 매핑
          }));
          setHospitalList([
            { value: 0, label: "병원 전체" },
            ...formattedOptions,
          ]);
          console.error("병원 목록이 반환되지 않았습니다.");
        }
      } catch (error) {
        console.error("병원 목록 불러오기 실패:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // 담당자 목록 가져오기
  const fetchManagers = async () => {
    if (managerList.length === 0) {
      try {
        const managerResponse = await axios.get(
          "/api/tm-workstatus/filter/users"
        ); // 담당자 목록 API 필요

        if (managerResponse.data) {
          const formattedOptions = managerResponse.data.map((manager) => ({
            value: manager.id, // 'id'를 'value'로 매핑
            label: manager.name || "null", // 'public_name'을 'label'로 매핑
          }));
          setManagerList([
            { value: 0, label: "담당자 전체" },
            ...formattedOptions,
          ]);
          console.error("담당자 목록이 반환되지 않았습니다.");
        }

        console.log(managerList);
      } catch (error) {
        console.error("필터 목록 불러오기 실패:", error);
      }
    }
  };

  useEffect(() => {
    fetchHospitals();
    if (loginUser.role !== undefined && loginUser.role !== 4) {
      fetchManagers();
    }
  }, [loginUser]);

  const handleHospitalChange = (selectedValue) => {
    setSelectedHospital(selectedValue);
  };
  const handleManagerChange = (selectedValue) => {
    setSelectedManager(selectedValue);
  };

  const handleDateShift = (direction) => {
    const { startDate, endDate } = shiftDateRange(
      selectedDate.startDate,
      selectedDate.endDate,
      direction
    );
    setSelectedDate({
      startDate,
      endDate,
    });
  };

  return (
    <div className="tmWorkStatus_filter">
      {/* 병원 선택 - 클라이언트는 안보임*/}
      {role !== 5 && (
        <CustomDropdown
          selectedValue={selectedHospital}
          options={hospitalList}
          onChange={handleHospitalChange}
          bigDrop={0}
          search={1}
          optionChecked={false}
          ischeckedopen={false}
        />
      )}
      {/* 담당자 선택 - 콜직원은 안보임 */}
      {role !== 4 && role !== 5 && (
        <CustomDropdown
          selectedValue={selectedManager}
          options={managerList}
          onChange={handleManagerChange}
          bigDrop={0}
          search={1}
          optionChecked={false}
          ischeckedopen={false}
        />
      )}
      {/* 날짜 선택 */}
      <div className="date_filter_box">
        분배날짜 선택
        <Dropdown
          label="date"
          selectedValue={selectedDate}
          setSelectedValue={setSelectedDate}
          isDatePicker={true} // ✅ 날짜 선택 모드 활성화
          placeholder="날짜 전체"
        />
        <div className="date-navigation">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="arrow-icon"
            onClick={() => handleDateShift("prev")}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            className="arrow-icon"
            onClick={() => handleDateShift("next")}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
