import React from "react";

function StatCard({ label, value, id, setNewCompany, dataFilterClean }) {
  const handleClick = () => {
    if (dataFilterClean) {
      // dataFilterClean이 존재하면 사용
      dataFilterClean();
    } else {
      // 그렇지 않으면 newClick id 값 저장
      setNewCompany(String(id));
    }
  };
  return (
    <div className="stat-card">
      <div className="stat-label">{label}</div>
      <div className="stat-value" onClick={handleClick}>
        {value}
        <span>건</span>
      </div>
    </div>
  );
}

export default StatCard;
