import axios from "axios";
import React, { useState, useEffect } from "react";
import AlertModal from "../../../components/AlertModal";

const TmDataList = ({
  loginUser,
  activeUsers,
  mainData = [], // 기본값 [] 설정
  currentPage = 1, // 기본값 1 설정
  totalPages = 1, // 기본값 1 설정
  totalItems = 0, // 기본값 0 설정
  itemsPerPage = 10, // 기본값 10 설정
  onPageChange = () => {}, // 기본값 빈 함수 설정
  follow_status,
  setFollow_status,
  loading,

  setSelectedRecordIds,
  selectedRecordIds,
}) => {
  const [selectedUserId, setSelectedUserId] = useState("");
  // const [selectedRecordIds, setSelectedRecordIds] = useState([]); // 체크된 데이터의 id 값을 상태로 관리
  const [listData, setListData] = useState(mainData);
  const [showAlert, setShowAlert] = useState(false); // AlertModal 표시 여부 상태
  const [alertMessage, setAlertMessage] = useState(""); // AlertModal 메시지 상태

  // 재통화날짜
  const handleFollowStatusChange = () => {
    setFollow_status((prevStatus) => {
      if (prevStatus === undefined) return true;
      if (prevStatus === true) return false;
      return undefined;
    });
  };

  useEffect(() => {
    setListData(mainData);
  }, [mainData]);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    date.setHours(date.getHours() - 9); // 9시간 빼기
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  // user_id와 name을 매핑하는 객체 생성
  const userMap = activeUsers.reduce((acc, user) => {
    acc[user.user_id] = user.name;
    return acc;
  }, {});

  // 기본 컬럼과 추가 컬럼 정의
  const defaultColumns = [
    "선택",
    "no.",
    "유입날짜",
    "지점",
    "이벤트명",
    "신청자 이름",
    "전화번호",
    "ip",
    "매체",
    "콜내역",
    "담당자",
    "재통화날짜",
  ];

  const addColumns = [
    "콜차수",
    "콜 메모",
    "이벤트 메모",
    "예약일",
    "방문상태",
    "결제일",
    "총 결제 예정 금액",
    "상담금액",
  ];
  const [selectedColumns, setSelectedColumns] = useState([]);

  // 추가 컬럼 체크박스 변경 이벤트
  const handleCheckboxChange = (column) => {
    setSelectedColumns((prev) =>
      prev.includes(column)
        ? prev.filter((col) => col !== column)
        : [...prev, column]
    );
  };

  // 각 컬럼에 맞게 데이터를 렌더링하는 함수
  const renderCell = (column, row, index) => {
    switch (column) {
      case "선택":
        return (
          <input
            type="checkbox"
            checked={selectedRecordIds.includes(row.id)}
            onChange={() => handleRecordCheckboxChange(row.id)}
          />
        );
      case "no.":
        return totalItems - (currentPage - 1) * itemsPerPage - index; // 페이지네이션을 고려한 번호 계산
      case "유입날짜":
        return row.date ? formatDate(row.date) : "-";
      case "지점":
        return row.hospital_name || "-"; // 지점 정보가 없다면 "-"
      case "이벤트명":
        return row.event_name || "-"; // 이벤트명 정보가 없다면 "-"
      case "신청자 이름":
        return row.name || "-";
      case "전화번호":
        return row.phone || "-";
      case "ip":
        return row.ip || "-";
      case "매체":
        return row.advertising_company_name || "-";
      case "콜내역":
        return row.call_history || "-";
      case "담당자":
        return userMap[row.manager] || "-"; // 변경된 담당자 반영
      case "재통화날짜":
        return row.follow_up_time ? formatDate(row.follow_up_time) : "-";
      case "콜차수":
        return row.call_count || "-";
      case "콜 메모":
        return row.call_memo || "-";
      case "이벤트 메모":
        return row.event_memo || "-";
      case "예약일":
        return row.reservation_date ? formatDate(row.reservation_date) : "-";
      case "방문상태":
        return row.visit_status || "-";
      case "결제일":
        return row.payment_date ? formatDate(row.payment_date) : "-";
      case "총 결제 예정 금액":
        return row.total_payment || "-";
      case "상담금액":
        return row.consultation_fee || "-";
      default:
        return "-";
    }
  };

  // 체크박스 변경 이벤트 핸들러
  const handleRecordCheckboxChange = (id) => {
    setSelectedRecordIds((prev) =>
      prev.includes(id)
        ? prev.filter((recordId) => recordId !== id)
        : [...prev, id]
    );
  };

  const handleUpdateUserId = async () => {
    if (selectedRecordIds.length === 0) {
      setAlertMessage("배분할 데이터를 선택해주세요.");
      setShowAlert(true);
      return;
    }

    try {
      await axios.put("/api/tm-main/manager", {
        ids: selectedRecordIds,
        user_id: selectedUserId,
      });

      // 선택된 레코드들의 담당자 변경
      setListData((prevData) =>
        prevData.map((item) =>
          selectedRecordIds.includes(item.id)
            ? { ...item, manager: selectedUserId } // manager 업데이트
            : item
        )
      );

      setSelectedRecordIds([]); // 체크 초기화

      setAlertMessage(`담당자님께 배분되었습니다.`);
      setShowAlert(true);
    } catch (error) {
      console.error("Error updating user ID:", error);
    }
  };

  return (
    <div className="tm-data-list">
      {showAlert && (
        <AlertModal
          message={alertMessage}
          onConfirm={() => setShowAlert(false)}
        />
      )}
      {/* 상단 영역: 추가 컬럼 체크박스와 담당자 선택 */}
      <div className="tmDataListHeader">
        <div className="checkbox-add-columns">
          {addColumns.map((column, index) => (
            <label key={index}>
              <input
                type="checkbox"
                checked={selectedColumns.includes(column)}
                onChange={() => handleCheckboxChange(column)}
              />
              <span>{column}</span>
            </label>
          ))}
        </div>
        <div className="manager-selection">
          <select
            id="managerSelect"
            value={selectedUserId}
            onChange={(e) => setSelectedUserId(e.target.value)}
          >
            <option value="">담당자 전체</option>
            {activeUsers.map((user) => (
              <option key={user.user_id} value={user.user_id}>
                {user.name}
              </option>
            ))}
          </select>
          <button onClick={handleUpdateUserId}>저장</button>
        </div>
      </div>

      {/* 테이블 영역 */}
      <div className="table-container">
        {loading ? (
          <div className="tmdata-spinner">로딩 중...</div> // 로딩 중일 때 스피너 표시
        ) : listData.length === 0 ? (
          <div className="tm-no-data">검색 값을 입력 후 조회하세요</div>
        ) : (
          <table
            style={{
              width:
                selectedColumns.length > 0
                  ? `${100 + selectedColumns.length * 10}%`
                  : "100%",
            }}
          >
            <thead>
              <tr>
                {[...defaultColumns, ...selectedColumns]
                  // .filter((col) => !(loginUser.role === 5 && col === "지점")) // role이 5이면 "지점" 제외
                  .map((col) => (
                    <th key={col}>
                      {col}
                      {col === "재통화날짜" && (
                        <button
                          className="follow-status-button"
                          onClick={handleFollowStatusChange}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/sort.png`}
                            alt="재통화날짜 정렬"
                          />
                          {/* {follow_status === true
                          ? "True"
                          : follow_status === false
                          ? "False"
                          : "None"} */}
                        </button>
                      )}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {listData.map((row, index) => (
                <tr key={row.id}>
                  {[...defaultColumns, ...selectedColumns]
                    // .filter((col) => !(loginUser.role === 5 && col === "지점"))
                    .map((col) => (
                      <td key={col}>{renderCell(col, row, index)}</td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* 페이지네이션 UI (전체 페이지 수가 1보다 클 때만 렌더링) */}
      {totalPages && totalPages > 1 && (
        <div className="pagination">
          <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
            <img
              src={process.env.PUBLIC_URL + "/images/page/start.png"}
              className="doubleArrow"
              alt="첫 페이지"
            />
          </button>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/page/before.png"}
              className="singleArrow"
              alt="이전 페이지"
            />
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1)
            .slice(
              (Math.ceil(currentPage / 10) - 1) * 10,
              Math.ceil(currentPage / 10) * 10
            )
            .map((page) => (
              <button
                key={page}
                onClick={() => onPageChange(page)}
                className={page === currentPage ? "currentPage" : ""}
              >
                {page}
              </button>
            ))}
          <button
            onClick={() => onPageChange(currentPage + 10)}
            disabled={currentPage + 10 > totalPages}
          >
            {"..."}
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/page/end.png"}
              className="singleArrow"
              alt="다음 페이지"
            />
          </button>
          <button
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/page/next.png"}
              className="doubleArrow"
              alt="마지막 페이지"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default TmDataList;
