import React, { useState, useEffect } from "react";
import axios from "axios";
import FilterInput from "./FilterInput";
import filterFields from "../../../utils/filterFields";
import DataInputModal from "./DataInputModal";

// 초기 필터 상태 생성
const initialFilterState = filterFields.reduce((acc, field) => {
  acc[field.name] = "";
  return acc;
}, {});

const TmFilter = ({
  onFilterChange,
  activeUsers, // 담당자
  filterValues, // 필터값
  setFilterValues, // 필터값 설정
  handleSearch, // 검색 함수

  loginUser, // 로그인 유저
  setCurrentPage, // 현재 페이지 설정
}) => {
  // const [filter, setFilter] = useState(initialFilterState);
  const [hospitals, setHospitals] = useState([]);
  const [advertisingCompanies, setAdvertisingCompanies] = useState([]);
  const [dataInputModalOpen, setDataInputModalOpen] = useState(false);

  // 부모의 filterValues가 변경되면 로컬 상태 업데이트
  useEffect(() => {
    setFilterValues(initialFilterState);
  }, []);

  const fetchListData = async () => {
    // try {
    //   const hospitalResponse = await axios.get("/api/list/hospitals");

    //   const hospitalOptions = hospitalResponse.data.items.map((hospital) => ({
    //     value: hospital.id.toString(),
    //     label: hospital.name,
    //   }));
    //   setHospitals([{ value: "", label: "병원 전체" }, ...hospitalOptions]);
    // } catch (error) {
    //   console.error("병원 데이터 불러오기 실패:", error);
    // }

    try {
      console.log(loginUser.role);
      if (loginUser.role === 5) {
        // role이 5면 본인의 hospital_id만 설정
        setHospitals([
          { value: loginUser.hospital_name_id.toString(), label: "내 병원" },
        ]);
      } else {
        // role이 5가 아닐 경우 전체 병원 목록 불러오기
        const hospitalResponse = await axios.get("/api/list/hospitals");
        const hospitalOptions = hospitalResponse.data.items.map((hospital) => ({
          value: hospital.id.toString(),
          label: hospital.name,
        }));
        setHospitals([{ value: "", label: "병원 전체" }, ...hospitalOptions]);
      }
    } catch (error) {
      console.error("병원 데이터 불러오기 실패:", error);
    }

    try {
      const companyResponse = await axios.get(
        "/api/list/advertising_companies"
      );
      const companyOptions = companyResponse.data.items.map((company) => ({
        value: company.id.toString(),
        label: company.name,
      }));
      setAdvertisingCompanies([
        { value: "", label: "매체 전체" },
        ...companyOptions,
      ]);
    } catch (error) {
      console.error("광고회사 데이터 불러오기 실패:", error);
    }
  };

  useEffect(() => {
    fetchListData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFilter = { ...filterValues, [name]: value };
    setFilterValues(newFilter);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(filterValues);
    setCurrentPage(1); // 검색 시 페이지 초기화
  };

  const handleReset = () => {
    setFilterValues(initialFilterState);
    onFilterChange(initialFilterState);
  };

  const handleOpenModal = () => {
    setDataInputModalOpen(true);
  };

  const handleCloseModal = () => {
    setDataInputModalOpen(false);
  };

  return (
    <div className="tm-filter">
      <form onSubmit={handleSubmit}>
        {filterFields.map((field) => {
          let fieldOptions = field.options;
          if (field.name === "hospital_name_id") {
            fieldOptions = hospitals;
          } else if (field.name === "advertising_company_id") {
            fieldOptions = advertisingCompanies;
          } else if (field.name === "manager") {
            fieldOptions = [
              { value: "", label: "담당자 전체" },
              ...(activeUsers || []).map((user) => ({
                value: user.user_id.toString(),
                label: user.name,
              })),
            ];
          }
          return (
            <FilterInput
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type}
              value={filterValues[field.name]}
              onChange={handleInputChange}
              options={fieldOptions}
            />
          );
        })}
        <div className="form-actions">
          <button className="searchBtn" type="submit">
            검색
          </button>
          <button className="resetBtn" type="button" onClick={handleReset}>
            검색 초기화
          </button>
        </div>
        <button className="dbInputBtn" type="button" onClick={handleOpenModal}>
          디비수동기입입력
        </button>
        {dataInputModalOpen && (
          <DataInputModal isOpen={handleOpenModal} onClose={handleCloseModal} />
        )}
      </form>
    </div>
  );
};

export default TmFilter;
