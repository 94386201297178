import { jwtDecode } from "jwt-decode";

export const getInitialLoginUser = () => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  let initialLoginUser = {
    user_id: null,
    name: null,
    role: null,
    hospital_name_id: null,
  };

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // 현재 시간 (초 단위)
      // console.log(currentTime);
      // console.log("decoded", decoded);
      if (decoded.exp && decoded.exp < currentTime) {
        // 토큰 만료 시 삭제
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        console.warn("토큰이 만료되었습니다.");
      } else {
        // 토큰 유효하면 로그인 정보 설정
        initialLoginUser = {
          user_id: decoded.userId,
          name: decoded.name,
          role: decoded.role,
          hospital_name_id: decoded.hospital_name_id,
        };
      }
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }

  // console.log(initialLoginUser);
  return initialLoginUser;
};
