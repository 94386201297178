import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import AlertModal from "../../components/AlertModal";

const AdExcelUpload = () => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pendingFile, setPendingFile] = useState(null); // 업로드 대기 중인 파일
  const fileInputRef = useRef(null);

  const handleFileUpload = async (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rawData = XLSX.utils.sheet_to_json(worksheet);

      // 키 변환
      const transformedData = rawData.map((row) => ({
        ad_title: row["광고제목"],
        ad_spend: row["광고비"],
        spend_date: row["소진 일자"],
      }));

      console.log("변환된 엑셀 데이터:", transformedData);
      const token = localStorage.getItem("token");

      // 백엔드로 데이터 POST 요청
      try {
        const response = await axios.post(
          "/api/excel/adspends",
          transformedData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // 헤더에 토큰 포함
            },
          }
        );
        console.log("서버 응답:", response.data);
        alert("저장되었습니다.");
      } catch (error) {
        console.error("업로드 실패:", error);
        alert("업로드 중 문제가 발생했습니다.");
      }
    };

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPendingFile(file); // 업로드 대기 중인 파일 저장
      setShowModal(true); // 모달 표시
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      setPendingFile(file); // 업로드 대기 중인 파일 저장
      setShowModal(true); // 모달 표시
    }
  };

  const handleClick = () => {
    fileInputRef.current.click(); // 숨겨진 input을 트리거
  };

  const handleConfirmUpload = () => {
    if (pendingFile) {
      handleFileUpload(pendingFile); // 파일 업로드 실행
      setPendingFile(null); // 대기 파일 초기화
    }
    setShowModal(false); // 모달 닫기
  };

  const handleCancelUpload = () => {
    setPendingFile(null); // 대기 파일 초기화
    setShowModal(false); // 모달 닫기
  };

  return (
    <div className="adexcel-container">
      {/* 숨겨진 파일 선택 input */}
      <input
        type="file"
        ref={fileInputRef}
        accept=".xlsx, .xls"
        className="adexcel-input-hidden"
        onChange={handleFileInputChange}
      />

      {/* 드래그 앤 드롭 영역 */}
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        className={`adexcel-drag-drop ${isDragOver ? "adexcel-drag-over" : ""}`}
      >
        <p className="adexcel-text">
          <span className="adexcel-title">엑셀파일 대량등록</span>
          또는 여기로 파일을 끌어오세요
        </p>
      </div>

      {/* 확인 모달 */}
      {showModal && (
        <AlertModal
          message="정말로 파일을 업로드하시겠습니까?"
          onConfirm={handleConfirmUpload}
          onCancel={handleCancelUpload}
        />
      )}
    </div>
  );
};

export default AdExcelUpload;
