import React from "react";
import "./alertModal.scss";

const ConfirmSaveModal = ({ message, onConfirm }) => {
  return (
    <div className="alert-modal">
      <div className="alert-modal-content">
        <img
          className="alert-modal-icon"
          src={`${process.env.PUBLIC_URL}/images/check_icon.png`}
          alt="확인 아이콘"
        />
        <p className="alert-modal-message">{message}</p>
        <div className="alert-modal-actions">
          {onConfirm && (
            <button className="alert-modal-button confirm" onClick={onConfirm}>
              확인
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmSaveModal;
