// utils/filterFields.js

const filterFields = [
  { label: "유입 날짜", name: "date", type: "date" },
  {
    label: "지점",
    name: "hospital_name_id",
    type: "select",
    options: [], // TmMain에서 병원 API 데이터를 통해 채워집니다.
  },
  {
    label: "콜내역",
    name: "call_history",
    type: "select",
    options: [
      { value: "", label: "콜내역 전체" },
      { value: "예약", label: "예약" },
      { value: "부재", label: "부재" },
      { value: "본인아님", label: "본인아님" },
      { value: "취소요청", label: "취소요청" },
      { value: "당일재통화", label: "당일재통화" },
      { value: "재통화요청", label: "재통화요청" },
      { value: "본인연락", label: "본인연락" },
      { value: "중복", label: "중복" },
      { value: "치과전달", label: "치과전달" },
      { value: "장기부재", label: "장기부재" },
      { value: "잠재고객", label: "잠재고객" },
      { value: "구환", label: "구환" },
    ],
  },
  {
    label: "방문상태",
    name: "visit_status",
    type: "select",
    options: [
      { value: "", label: "방문상태 전체" },
      { value: "결제", label: "결제" },
      { value: "상담", label: "상담" },
      { value: "노쇼", label: "노쇼" },
      { value: "재통화요청", label: "재통화요청" },
      { value: "개인일정취소", label: "개인일정취소" },
      { value: "상담종료", label: "상담종료" },
      { value: "재예약", label: "재예약" },
    ],
  },
  {
    label: "매체",
    name: "advertising_company_id",
    type: "select",
    options: [], // TmMain에서 광고회사 API 데이터를 통해 채워집니다.
  },
  { label: "재통화날짜", name: "follow_up_time", type: "date" },
  {
    label: "담당자",
    name: "manager",
    type: "select",
    options: [], // TmFilter에서 activeUsers 프롭스로 채워집니다.
  },
  {
    label: "콜차수",
    name: "call_count",
    type: "select",
    options: [
      { value: "", label: "콜차수 전체" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
    ],
  },
  { label: "예약날짜", name: "reservation_date", type: "date" },
  { label: "신청자 or 예약자 이름", name: "reserver_name", type: "text" },
  { label: "신청자 or 예약자 번호", name: "reserver_phone", type: "text" },
  // { label: "ip", name: "ip", type: "text" },
  { label: "총 결제 예정 금액", name: "total_payment", type: "number" },
  { label: "결제일", name: "payment_date", type: "date" },
  { label: "이벤트 메모", name: "event_memo", type: "text" },
  { label: "콜 메모", name: "call_memo", type: "text" },
];

export default filterFields;
