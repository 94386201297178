// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import NotFound from "./components/NotFound.jsx";
import CodeGenerator from "./pages/code/CodeGenerator.jsx";
import CodePage from "./pages/code/CodePage.jsx";

import Layout from "./layout/Layout.jsx";

// tm
import TmMain from "./pages/tm/tmmain/TmMain.jsx";
import NoticeBoard from "./pages/tm/notice/NoticeBoard.jsx";
import NoticeDetail from "./pages/tm/notice/NoticeDetail.jsx";
import Calendar from "./pages/tm/tmCall/Calendar.jsx";
import CustomerDetail from "./pages/tm/tmCall/CustomerDetail.jsx";
import WorkStatus from "./pages/tm/workStatus/WorkStatus.jsx";
// admin
import TabComponent from "./pages/admin/listsetting/TabComponent.jsx";
import UserManagement from "./pages/admin/user/UserManagement.jsx";
import Blacklist from "./pages/admin/blacklist/Blacklist.jsx";
import UrlCodeMetrics from "./pages/admin/metrics/UrlCodeMetrics.jsx";
// login
import Login from "./pages/login/Login.jsx";
//
import CustomerDataPage from "./pages/customorDataPage/CustomorDataPage.jsx";
import ExpenseInputForm from "./pages/advertisingExpenses/ExpenseInput.jsx";
import AdvertisingExpenses from "./pages/advertisingExpenses/AdvertisingExpenses.jsx";
import AdExpenseStatus from "./pages/advertisingExpenses/AdExpenseStatus.jsx";

import Wonho from "./pages/advertisingExpenses/Wonho.jsx";
import RevenueAndCostSetting from "./pages/revenueAndCostSetting/RevenueAndCostSetting.jsx";
import { jwtDecode } from "jwt-decode";
import { getInitialLoginUser } from "./utils/loginauth.js";
import TaxData from "./components/TaxData.jsx";

// import TestForm from "./pages/tm/tmCall/TestForm.js";

function App() {
  const [loginUser, setLoginUser] = useState(getInitialLoginUser());
  const tmRoutes = (
    <>
      {loginUser.role !== 4 && (
        <>
          {/* TM - 예약 및 방문현황*/}
          <Route path="/tm" element={<TmMain loginUser={loginUser} />} />
        </>
      )}
      {loginUser.role === 4 && (
        <Route path="/tm" element={<Navigate replace to="/tm/workstatus" />} />
      )}
      {/* TM - 담당자 업무 현황*/}
      <Route
        path="/tm/workstatus"
        element={<WorkStatus loginUser={loginUser} />}
      />

      {/* TM - 고객 상세 정보*/}
      <Route
        path="/tm/customerDetail/:customerId"
        element={<CustomerDetail loginUser={loginUser} />}
      />

      {/* TM - 예약달력*/}
      <Route path="/tm/calendar" element={<Calendar loginUser={loginUser} />} />

      {loginUser.role !== 5 && (
        <>
          {/* TM - 공지*/}
          <Route path="/tm/notice" element={<NoticeBoard />} />
          <Route path="/tm/notice/new" element={<NoticeDetail />} />
          <Route path="/tm/notice/:id" element={<NoticeDetail />} />
        </>
      )}
      {loginUser.role === 5 && (
        <Route
          path="/db"
          element={<CustomerDataPage title="접수자 리스트" get_status={1} />}
        />
      )}
    </>
  );

  const generalRoutes = (
    <>
      {/* 이건 머지???----------------------------------------------------*/}
      {/* <Route path="/:code/:urlCode" element={<CodePage />} /> */}

      {/* <Route path="/main" element={<CustomerDataPage title="메인보고서" />} /> */}
      {/* 디비리스트 */}
      <Route
        path="/db"
        element={<CustomerDataPage title="접수자 리스트" get_status={1} />}
      />

      {/* 광고비관리 */}
      <Route path="/ad-cost/input" element={<AdvertisingExpenses />} />
      <Route path="/ad-cost/monitor" element={<AdExpenseStatus />} />

      {/* 코드생성 */}
      <Route path="/code-generator" element={<CodeGenerator />} />

      {/* 관리자 페이지*/}
      <Route path="/admin/listsetting" element={<TabComponent />} />
      <Route path="/admin/UserManagement" element={<UserManagement />} />
      <Route path="/admin/metrics" element={<UrlCodeMetrics />} />
      {loginUser.role === 1 && (
        <Route path="/admin/revenue" element={<RevenueAndCostSetting />} />
      )}
      <Route path="/admin/blacklist" element={<Blacklist />} />

      {/* 휴지통 */}
      <Route
        path="/trashCanData"
        element={<CustomerDataPage title="휴지통" get_status={0} />}
      />
    </>
  );

  // 이제 setRole 함수를 props로 전달할 수 있습니다.

  const allRoutes = (
    <>
      <Route
        path="/login"
        element={<Login loginUser={loginUser} setLoginUser={setLoginUser} />}
      />
      <Route path="/tax" element={<TaxData />} />
      {generalRoutes}
      {tmRoutes}
      <Route path="*" element={<NotFound />} />
    </>
  );

  const renderRoutes = () => {
    // if (loginUser.role === 4) {
    //   return <Navigate to="/tm/workstatus" replace />;
    // }
    // 로그인 상태에 따라 다른 라우트를 반환하는 함수
    return (
      <Routes>
        {loginUser.role === 1 ? (
          allRoutes // role 1: 모든 경로
        ) : [3, 4, 5].includes(loginUser.role) ? (
          tmRoutes // role 3, 4, 5: TM 관련 경로만
        ) : loginUser.role === 2 ? (
          <>
            {generalRoutes}
            {tmRoutes}
          </>
        ) : (
          // role 2: Wonho2를 제외한 모든 경로
          <Route
            path="/login"
            element={
              <Login loginUser={loginUser} setLoginUser={setLoginUser} />
            }
          />
        )}
      </Routes>
    );
  };

  return (
    <div className="App">
      <Router>
        <Layout name={loginUser.name} role={loginUser.role}>
          {renderRoutes()}
        </Layout>
      </Router>
    </div>
  );
}

export default App;
