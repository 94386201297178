import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../components/AlertModal";
function SideMenu({ role }) {
  const [openIndexes, setOpenIndexes] = useState({});
  const [modals, setModals] = useState({ isOpen: false, navigateTo: null });
  const location = useLocation();
  const navigate = useNavigate();

  // 상태 업데이트 후 네비게이션 실행
  // useEffect(() => {
  //   if (modals.navigateTo && !modals.isOpen) {
  //     navigate(modals.navigateTo);
  //   }
  // }, [modals, navigate]);

  const handleCloseModal = () => {
    setModals({ isOpen: false, navigateTo: null });
  };

  const handleNavigation = (e, path, hasSubMenu) => {
    // 조건에 따라 기본 동작 방지
    if (
      location.pathname.includes("/tm/CustomerDetail") &&
      path !== location.pathname
    ) {
      e.preventDefault(); // '/tm/CustomerDetail' 페이지에 있을 때만 기본 동작을 방지
      setModals({ isOpen: true, navigateTo: path });
    } else if (!hasSubMenu) {
      navigate(path); // 서브메뉴가 없으면 지정된 경로로 즉시 이동
    }
  };

  const toggleSubMenu = (index) => {
    setOpenIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const menuItems = [
    { title: "메인", path: "/main" },
    { title: "디비리스트", path: "/db" },
    {
      title: "광고비관리",
      path: "/ad-cost/input",
      subMenu: [
        { title: "광고비입력", path: "/ad-cost/input" },
        { title: "광고비 소진 현황", path: "/ad-cost/monitor" },
      ],
    },
    { title: "성과 리포트", path: "/dd" },
    {
      title: "TM",
      path: "/tm",
      subMenu: [
        ...(role !== 4 ? [{ title: "예약 및 방문현황", path: "/tm" }] : []),
        { title: "담당자 업무 현황", path: "/tm/workstatus" },
        { title: "예약달력", path: "/tm/calendar" },
        ...(role !== 5 ? [{ title: "공지", path: "/tm/notice" }] : []),
      ],
    },
    {
      title: "코드 생성",
      path: "/code-generator",
    },
    {
      title: "관리자 페이지",
      path: "/admin/listsetting",
      subMenu: [
        { title: "병원/이벤트/매체 리스트", path: "/admin/listsetting" },
        { title: "블랙리스트", path: "/admin/blacklist" },
        { title: "계정관리", path: "/admin/UserManagement" },
        ...(role !== 2
          ? [{ title: "매출 및 실비 설정", path: "/admin/revenue" }]
          : []),
        { title: "디비 개수", path: "/admin/metrics" },
      ],
    },
    { title: "휴지통", path: "/trashCanData" },
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    if ([1, 2].includes(role)) {
      return true;
    } else if (
      [3, 4, 5].includes(role) &&
      (item.path.startsWith("/tm") || (role === 5 && item.path === "/main"))
    ) {
      return true;
    }
    return false;
  });

  const isMainMenuActive = (menu) => {
    return (
      location.pathname === menu.path ||
      (menu.subMenu &&
        menu.subMenu.some((subItem) => location.pathname === subItem.path))
    );
  };

  // 로그인 페이지 여부 확인
  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) return null; // 로그인 페이지면 SideMenu를 렌더링하지 않음

  return (
    <nav>
      {modals.isOpen && (
        <AlertModal
          message={`현재 페이지에서 나가시겠습니까?\n 변경 사항이 저장되지 않을 수 있습니다.`}
          onConfirm={() => {
            navigate(modals.navigateTo); // 저장된 경로로 이동
            handleCloseModal();
          }}
          onCancel={handleCloseModal}
        />
      )}

      <ul className="main_menu">
        {filteredMenuItems.map((item, index) => {
          const isActive = isMainMenuActive(item);

          return (
            <li key={index}>
              <Link
                to={item.path}
                onClick={(e) => {
                  handleNavigation(e, item.path, item.subMenu);
                  if (item.subMenu) toggleSubMenu(index);
                }}
                style={{ color: isActive ? "#77c2ff" : "white" }}
              >
                {item.title}
              </Link>
              {item.subMenu && (
                <ul
                  className="sub_menu"
                  style={{ display: openIndexes[index] ? "none" : "block" }}
                >
                  {item.subMenu.map((subItem, subIndex) => {
                    const isSubActive = location.pathname === subItem.path;
                    return (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          style={{ color: isSubActive ? "#77c2ff" : "white" }}
                          onClick={(e) => {
                            handleNavigation(e, subItem.path);
                          }}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default SideMenu;
