export const shiftDateRange = (startDate, endDate, direction) => {
  const startTimestamp = new Date(startDate).getTime(); // 시작 날짜 타임스탬프
  const endTimestamp = new Date(endDate).getTime(); // 종료 날짜 타임스탬프
  const diffTime = endTimestamp - startTimestamp; // 날짜 차이 (밀리초)

  let newStartTimestamp, newEndTimestamp;
  if (direction === "prev") {
    // 이전 날짜로 이동
    newStartTimestamp = startTimestamp - diffTime - 86400000;
    newEndTimestamp = endTimestamp - diffTime - 86400000;
  } else {
    // 다음 날짜로 이동
    newStartTimestamp = startTimestamp + diffTime + 86400000;
    newEndTimestamp = endTimestamp + diffTime + 86400000;
  }

  // KST 보정: 기존 localStorage의 Date 객체를 활용하여 직접 timestamp로 변환 후 유지
  const newStartDate = new Date(newStartTimestamp);
  const newEndDate = new Date(newEndTimestamp);

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
};
