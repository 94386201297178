import React, { useEffect, useState } from "react";
import axios from "axios";
import "./calendar.scss";
import HospitalFilter from "../../revenueAndCostSetting/HospitalFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../admin/listsetting/CustomDropdown";
import { shiftDateRange } from "../../../utils/shiftdaterange";
const Calendar = ({ loginUser }) => {
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [hospitalNameId, setHospitalNameId] = useState(() => {
    // 로컬 스토리지에서 병원 선택 값을 불러옵니다. 없으면 null
    return (
      loginUser.hospital_name_id ||
      sessionStorage.getItem("hospitalNameId") ||
      null
    );
  });
  const [year, setYear] = useState(() => {
    return sessionStorage.getItem("selectedYear") || new Date().getFullYear();
  });
  const [month, setMonth] = useState(() => {
    return sessionStorage.getItem("selectedMonth") || new Date().getMonth() + 1;
  }); // 월은 0부터 시작하므로 +1
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const visitStatus = ["결제", "상담", "재예약"];
  const notVisitStatus = ["노쇼", "재통화요청", "개인일정취소", "상담종료"];

  const today = new Date();

  const navigate = useNavigate();

  const handleReservationClick = (customerId) => {
    // 예약 클릭 시 /tm/CustomerDetail로 이동하면서 customer_id 전달
    navigate(`/tm/CustomerDetail/${customerId}`);
  };

  // 날짜 변경 시 세션 스토리지에 값 저장
  useEffect(() => {
    loginUser.role !== 5 &&
      sessionStorage.setItem("hospitalNameId", hospitalNameId);
    sessionStorage.setItem("selectedYear", year);
    sessionStorage.setItem("selectedMonth", month);
  }, [hospitalNameId, year, month]);

  //-------------------------------------
  useEffect(() => {
    const fetchHospitalOptions = async () => {
      try {
        const response = await axios.get("/api/list/hospitals");
        if (response.data.items) {
          const formattedOptions = response.data.items.map((hospital) => ({
            value: hospital.id, // 'id'를 'value'로 매핑
            label: hospital.public_name || "null", // 'public_name'을 'label'로 매핑
          }));
          setHospitalOptions(formattedOptions); // 변환된 객체 배열로 상태 설정  } else {
          console.error("병원 목록이 반환되지 않았습니다.");
        }
      } catch (error) {
        console.error("병원 목록을 불러오는 중 오류 발생:", error);
      }
    };

    fetchHospitalOptions();
  }, []);

  const handleHospitalChange = (selectedValue) => {
    setHospitalNameId(selectedValue);
  };

  // API 호출 함수
  const fetchReservations = async () => {
    if (!hospitalNameId) {
      setReservations([]); // 병원 선택 안되었을 때 예약 데이터 초기화
      return; // 병원이 선택되지 않으면 API 호출을 하지 않음
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get("/api/tm-Call/reservations", {
        params: { hospital_name_id: Number(hospitalNameId), year, month },
      });
      setReservations(response.data); // API 응답 데이터 설정
      console.log(reservations);
    } catch (err) {
      console.error(err);
      setError("예약 데이터를 가져오는 데 실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // 달력 생성 함수
  const generateCalendar = () => {
    const firstDayOfMonth = new Date(year, month - 1, 1); // 월은 0부터 시작
    const lastDayOfMonth = new Date(year, month, 0); // 다음 달의 0일은 현재 달의 마지막 날
    const calendarDays = [];
    const startDay = firstDayOfMonth.getDay(); // 해당 월의 시작 요일 (0: 일요일)
    const totalDays = lastDayOfMonth.getDate(); // 해당 월의 총 일수
    const lastDayOfWeek = lastDayOfMonth.getDay(); // 해당 월의 마지막 요일

    // 이전 달 공백 채우기
    for (let i = 0; i < startDay; i++) {
      calendarDays.push(null);
    }

    // 해당 월의 날짜 추가
    for (let day = 1; day <= totalDays; day++) {
      calendarDays.push(day);
    }

    // 다음 달의 시작 공백 채우기
    for (let i = lastDayOfWeek + 1; i < 7; i++) {
      calendarDays.push(null);
    }

    return calendarDays;
  };

  const calendarDays = generateCalendar();

  // 예약 데이터 필터링 함수
  const getReservationsForDate = (date) => {
    return reservations.filter((res) => {
      const reservationDate = res.reservation_date.slice(8, 10); // '2025-01-03' 형식으로 날짜만 잘라냄
      return Number(reservationDate) === date; // 필터 조건에 맞는 데이터만 반환
    });
  };

  useEffect(() => {
    fetchReservations();
  }, [hospitalNameId, month]);

  const handleMonthChange = (direction) => {
    if (!hospitalNameId) {
      alert("병원을 선택하세요");
      return;
    }

    let newMonth = Number(month) + direction;
    let newYear = Number(year);

    if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    } else if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    }

    setYear(newYear);
    setMonth(newMonth);
    setReservations([]);
  };

  return (
    <div className="tm-calendar container">
      <div className="calendar-filters">
        {loginUser.role !== 5 && (
          // <HospitalFilter
          //   selectedHospital={hospitalNameId}
          //   setSelectedHospital={setHospitalNameId}
          // />
          <CustomDropdown
            selectedValue={hospitalNameId}
            options={hospitalOptions}
            onChange={handleHospitalChange}
            bigDrop={0}
            search={1}
            optionChecked={false}
            ischeckedopen={false}
          />
        )}

        <div className="year-month-button">
          <button className="month-btn" onClick={() => handleMonthChange(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <span className="year-month">{`${year}년 ${month
            .toString()
            .padStart(2, "0")}월`}</span>

          <button className="month-btn" onClick={() => handleMonthChange(1)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className="visit-legend">
          <div className="visited">
            <span></span>
            <p>내원</p>
          </div>
          <div className="not-visited">
            <span></span>
            <p>미내원</p>
          </div>
        </div>
      </div>
      {error ? (
        <p className="calendar-error-message">{error}</p>
      ) : (
        <div className="calendar">
          {/* {loading && (
            <p className={`calendar-loading ${loading ? "show" : ""}`}></p>
          )} */}

          <div className="calendar-header">
            {["일", "월", "화", "수", "목", "금", "토"].map((day, index) => (
              <div
                key={day}
                className={`calendar-day ${index === 0 ? "sunday" : ""} ${
                  index === 6 ? "saturday" : ""
                }`}
              >
                {day}
              </div>
            ))}
          </div>

          <div className="calendar-grid">
            {calendarDays.map((day, index) => (
              <div
                key={index}
                className={`calendar-cell ${
                  day === today.getDate() &&
                  today.getMonth() + 1 === month &&
                  today.getFullYear() === year
                    ? "today"
                    : ""
                }`}
              >
                {day && (
                  <>
                    <div className="calendar-date">{day}</div>
                    <div className="calendar-reservation-list">
                      {getReservationsForDate(day).map((res) => {
                        return (
                          <div
                            key={res.id}
                            className={`calendar-reservation-item ${
                              visitStatus.includes(res.visit_status)
                                ? "visit_status"
                                : "not_visit_status"
                            }`}
                            onClick={() =>
                              handleReservationClick(res.customer_id)
                            } // 클릭 시 해당 customer_id 전달
                          >
                            {/* 시간 (윗줄) */}
                            <div className="calendar-reservation-time">
                              {res.reservation_date.slice(11, 16)}{" "}
                              {/* 14:00처럼 시간만 추출 */}
                            </div>
                            {/* 예약자 이름과 이벤트이름 (아랫줄) */}
                            <div className="calendar-reservation-details">
                              {res.reserver_name} {res.event_name}{" "}
                              {res.reserver_phone}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
