import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmSaveModal from "../../components/ConfirmSaveModal";
import AlertModal from "../../components/AlertModal";

function ExpenseInputForm({ formData, searchStatus, setSearchStatus }) {
  const [codes, setCodes] = useState([]);
  const [adSpendings, setAdSpendings] = useState({});
  const [showModal, setShowModal] = useState(false); // 모달 상태 추가
  const [modalType, setModalType] = useState(""); // ✅ 모달 종류

  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const [itemsPerPage] = useState(10); // 페이지당 항목 수

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalType("");
  };

  const fetchUrlCodeSettings = async () => {
    try {
      const response = await axios.get("/api/adexpense/list", {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search_title: formData.search_title || "",
          search_hospital_name_id: formData.search_hospital_name_id || "",
        },
      });

      if (response.data) {
        setCodes(response.data.codesWithDetails);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      }
    } catch (error) {
      console.error("Failed to fetch url code settings:", error);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleAmountChange = (id, value) => {
    // ',' 제거 후 숫자로 변환
    const amount = parseFloat(value.replace(/,/g, "")); // 쉼표 제거 후 숫자로 변환

    if (!isNaN(amount)) {
      setAdSpendings({
        ...adSpendings,
        [id]: {
          ...adSpendings[id],
          amount: amount,
        },
      });
    }
  };

  useEffect(() => {
    fetchUrlCodeSettings();
  }, [formData.search_hospital_name_id, currentPage]);

  useEffect(() => {
    if (searchStatus) {
      fetchUrlCodeSettings();
    }
  }, [searchStatus]);

  // 페이지 네이션
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // 페이지 변경
    }
  };

  const saveAdSpending = async (codeId, hospitalNameId, hospital_name) => {
    const spendingToCreate = adSpendings[codeId];
    if (
      !spendingToCreate ||
      !spendingToCreate.amount ||
      !spendingToCreate.date
    ) {
      openModal("error");
      // alert("광고비와 소진 일자를 모두 입력해주세요.");
      return;
    }

    const createFields = {
      // user_id: 1, // 실제 사용자 ID로 교체 필요
      url_code_setting_id: codeId,
      ad_spend: spendingToCreate.amount,
      spend_date: spendingToCreate.date,
      hospital_name_id: hospitalNameId, // hospital_name_id 추가
      hospital_name: hospital_name,
    };

    try {
      const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기
      const response = await axios.post("/api/adexpense/new", createFields, {
        headers: {
          Authorization: `Bearer ${token}`, // 요청 헤더에 토큰 추가
        },
      });

      if (response.status === 201) {
        openModal("success");
        // alert("광고비가 성공적으로 저장되었습니다.");

        // 입력 필드 초기화
        setAdSpendings((prev) => ({
          ...prev,
          [codeId]: { amount: "", date: "" },
        }));
      } else {
        throw new Error("Failed to save");
      }
    } catch (error) {
      console.error("Failed to save ad spending:", error);
      alert("광고비 저장에 실패했습니다.");
    }
  };

  return (
    <div className="expenseInputForm">
      <table>
        <thead>
          <tr>
            <th>광고 제목</th>
            <th>매체</th>
            <th>광고비</th>
            <th>소진 일자</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {codes.map((code) => (
            <tr key={code.id}>
              <td>{code.ad_title}</td>
              <td>{code.advertising_company_name}</td>
              <td>
                <input
                  type="text"
                  value={
                    adSpendings[code.id]?.amount
                      ? formatNumber(adSpendings[code.id].amount)
                      : ""
                  }
                  onChange={(e) => handleAmountChange(code.id, e.target.value)}
                  placeholder="광고비"
                />
              </td>
              <td className="spend_date">
                <input
                  type="date"
                  value={adSpendings[code.id]?.date || ""}
                  onChange={(e) =>
                    setAdSpendings({
                      ...adSpendings,
                      [code.id]: {
                        ...adSpendings[code.id],
                        date: e.target.value,
                      },
                    })
                  }
                />
                <input
                  type="text"
                  placeholder="연도-월-일"
                  value={adSpendings[code.id]?.date || ""}
                  // onChange={(e) =>
                  //   setAdSpendings({
                  //     ...adSpendings,
                  //     [code.id]: {
                  //       ...adSpendings[code.id],
                  //       date: e.target.value,
                  //     },
                  //   })
                  // }
                />
              </td>
              <td>
                <button
                  type="button"
                  className="save-button"
                  onClick={() =>
                    saveAdSpending(
                      code.id,
                      code.hospital_name_id,
                      code.hospital_name
                    )
                  } // hospital_name_id 전달
                >
                  저장
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && modalType === "success" && (
        <ConfirmSaveModal
          title="저장 완료"
          message="광고비가 성공적으로 저장되었습니다."
          onConfirm={closeModal}
        />
      )}
      {showModal && modalType === "error" && (
        <AlertModal
          title="입력 오류"
          message="광고비와 소진 일자를 모두 입력해주세요."
          onConfirm={closeModal}
        />
      )}

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
}

export default ExpenseInputForm;
