import { useEffect } from "react";
import Dropdown from "./Dropdown";

const Search = ({
  searchField,
  setSearchField,
  searchKeyword,
  setSearchKeyword,
  searchList,
  handleFilterChange,
}) => {
  return (
    <div className="searchBox">
      {/* 검색어 선택 */}
      <Dropdown
        label="searchField"
        selectedValue={searchField}
        setSelectedValue={setSearchField}
        dataList={searchList}
        placeholder="검색어 선택"
      />
      <div className="input-container">
        <input
          type="text"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder="검색어 입력"
        />
        <button onClick={handleFilterChange}>검색</button>
      </div>
    </div>
  );
};
export default Search;
