import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TaxData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post("/api/customer/searchtax", {
        url_code_setting_id: 10,
        page: page,
        limit: limit,
      });
      setData(response.data.data);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (error) {
      setError("데이터를 가져오는 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="tax-randding-container">
      {loading && <p>로딩 중...</p>}
      {error && <p>{error}</p>}
      <table className="tax-randding-table">
        <thead>
          <tr>
            <th>이름</th>
            <th>전화번호</th>
            <th>날짜</th>
            <th>지점</th>
            <th>지역</th>
            <th>IP 주소</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const [branch, region] = item.customer_option.split(',');
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.date}</td>
                <td>{branch}</td>
                <td>{region}</td>
                <td>{item.ip}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="tax-randding-pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1} className="tax-randding-button">
          Previous
        </button>
        <span className="tax-randding-page-info">Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="tax-randding-button">
          Next
        </button>
      </div>
      <style jsx>{`
        .tax-randding-container {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .tax-randding-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        .tax-randding-table th, .tax-randding-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        .tax-randding-table th {
          background-color: #f2f2f2;
        }
        .tax-randding-pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
        .tax-randding-button {
          margin: 0 5px;
          padding: 10px 20px;
          border: none;
          background-color: #007bff;
          color: white;
          cursor: pointer;
        }
        .tax-randding-button:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        .tax-randding-page-info {
          margin: 0 10px;
        }
      `}</style>
    </div>
  );
};

export default TaxData;