const StatusCounts = ({ statusCounts }) => {
  const counts = [
    {
      key: "totalItems",
      label: "콜 전체",
      count: statusCounts.totalItems,
    },
    {
      key: "todayEmptyMemosCount",
      label: "신규",
      count: statusCounts.todayEmptyMemosCount,
    },
  ];

  const groupedCounts = [
    {
      key: "reservationCount",
      label: "예약",
      count: statusCounts.reservationCount,
    },
    {
      key: "absenceCount",
      label: "부재",
      count: statusCounts.absenceCount,
    },
    {
      key: "cancellationsCount",
      label: "취소",
      count: statusCounts.cancellationsCount,
    },
  ];

  const reservationRate = {
    key: "reservationRate",
    label: "예약률",
    count: `${(
      (statusCounts.reservationCount / statusCounts.totalItems) *
      100
    ).toFixed(1)}`,
  };

  return (
    <div className="tmStatusCounts_container">
      {counts.map((item) => (
        <ul key={item.key}>
          <li className={item.key}>
            <h4>{item.label}</h4>
            <p>
              <span>{item.count}</span>
              {item.key !== "reservationRate" ? "건" : ""}
            </p>
          </li>
        </ul>
      ))}
      <ul className="groupedCounts">
        {groupedCounts.map((item) => (
          <li key={item.key} className={item.key}>
            <h4>{item.label}</h4>
            <p>
              <span>{item.count}</span>건
            </p>
          </li>
        ))}
      </ul>
      <ul className={reservationRate.key}>
        <li className={reservationRate.key}>
          <h4>{reservationRate.label}</h4>
          <p>
            <span>{reservationRate.count}</span>%
          </p>
        </li>
      </ul>
    </div>
  );
};
export default StatusCounts;
