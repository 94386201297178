import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { format } from "date-fns";

function TmExcel() {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    handleUpload(file); // 파일 선택 시 업로드 함수 호출
  };

  const handleUpload = async (file) => {
    if (!file) {
      alert("파일을 선택해주세요.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const formattedData = jsonData.map((row) => ({
          date: formatExcelDate(row["일자"]),
          name: row["신청자이름"],
          phone: row["신청자번호"],
          reserver_name: row["예약자이름"],
          reserver_phone: row["예약자번호"],
          call_history: row["콜내역"],
          call_count: row["콜차수"],
          reservation_date: formatExcelDate(row["예약날짜"]),
          payment_date: formatExcelDate(row["결제날짜"]),
          visit_status: row["방문상태"],
          event_memo: row["이벤트 메모"],
          call_memo: row["통화메모"],
          follow_up_time: formatExcelDate(row["재통화날짜"]),
          total_payment: row["총결제금액"],
          consultation_fee: row["상담금액"],
          manager: row["담당자"],
          distribution_time: formatExcelDate(row["담당자배정시간"]),
        }));

        console.log(formattedData);
        const response = await axios.post("/api/excel/tm", {
          data: formattedData,
        });
        alert("업로드 성공!");
      } catch (error) {
        alert("업로드 실패: " + error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleButtonClick = () => {
    document.getElementById("file-upload").click();
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".xlsx, .xls"
        style={{ display: "none" }}
        id="file-upload"
      />
      <button onClick={handleButtonClick} className="upload-button">
        엑셀업로드
      </button>
      <style jsx>{`
        .upload-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          background-color: #007bff;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: background-color 0.3s ease;
        }
        .upload-button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
}

// 엑셀 날짜 포맷을 일반 날짜로 변환
// function formatExcelDate(excelDate) {
//   const date = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
//   return format(date, "yyyy-MM-dd HH:mm:ss");
// }

function formatExcelDate(excelDate) {
  // Excel 날짜 시스템에서 1900년 1월 1일을 기준으로 계산
  const date = new Date((excelDate - 25569) * 86400 * 1000); // Excel date to JS Date

  if (isNaN(date)) {
    return null; // 잘못된 날짜 처리
  }

  return date.toISOString().slice(0, 19); // 날짜 형식: yyyy-MM-ddTHH:mm:ss
}

export default TmExcel;
