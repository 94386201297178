import React, { useState, useEffect } from "react";
import axios from "axios";
import TmFilter from "./TmFilter";
import TmDataList from "./TmDataList";
import "./TmMain.scss";
import TitleBox from "../../../components/TitleBox";
import TmExcel from "../../admin/blacklist/TmExcel";

const TmMain = ({ loginUser }) => {
  // 데이터 관련 상태
  const [activeUsers, setActiveUsers] = useState([]); // 담당자 데이터
  const [mainData, setMainData] = useState([]); // 검색(필터)된 데이터
  const [follow_status, setFollow_status] = useState(undefined);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [selectedRecordIds, setSelectedRecordIds] = useState([]); // 체크된 데이터의 id 값을 상태로 관리

  // 페이지네이션 상태
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const [totalItems, setTotalItems] = useState(0); // 전체 항목 수

  const itemsPerPage = 10; // 한 페이지당 항목 수

  // 필터 관련 상태
  const [filterValues, setFilterValues] = useState({});

  // 첫 마운트 여부 확인 상태
  const [isMounted, setIsMounted] = useState(false);

  // 담당자 데이터 가져오기
  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const response = await axios.get("/api/tm-Main/user");
        setActiveUsers(response.data);
      } catch (error) {
        console.error("담당자 데이터를 불러오는 데 실패했습니다:", error);
      }
    };

    fetchActiveUsers();
  }, []);

  // 검색 버튼 클릭 또는 페이지, 필터 값 변경 시 호출
  const handleSearch = async (filters) => {
    try {
      setLoading(true); // 로딩 상태 시작

      const token = localStorage.getItem("token");

      // 빈 문자열을 제외하고 유효한 필터만 추출
      const validFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => {
          if (value !== "") {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      // follow_status 필터 추가
      if (follow_status !== "") {
        validFilters.follow_status = follow_status;
      }

 const response = await axios.post(
      "/api/tm-main/main",
      {
        ...validFilters,
        page: currentPage,
        limit: itemsPerPage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // 요청 헤더에 토큰 추가
        },
      }
    );


      // 서버 응답에서 items, totalPages, totalItems를 받아온다고 가정
      setMainData(response.data.items);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
      setSelectedRecordIds([]); // 검색 시 체크된 데이터 초기화
      setLoading(false); // 로딩 상태 시작
    } catch (error) {
      console.error("검색 데이터 불러오기 실패:", error);
    }
  };

  // 필터 값 변경 시 처리 (필터 변경 시 페이지는 1로 초기화)
  const handleFilterChange = (filter) => {
    setFilterValues(filter);
    setCurrentPage(1);
  };

  // currentPage가 변경될 때마다 검색 호출
  useEffect(() => {
    if (isMounted) {
      handleSearch(filterValues);
    } else {
      setIsMounted(true);
    }
  }, [currentPage, follow_status]);

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="tm-main container_flex">
      {/* TM데이터 임시 */}
      <TmExcel />
      <div className="container_left">
        <TitleBox mainmenu="TM" submenu="예약 및 방문현황" />
        <div className="tm-data">
          {/* 페이지네이션 관련 상태와 핸들러를 프롭스로 전달 */}
          <TmDataList
            loginUser={loginUser}
            activeUsers={activeUsers}
            mainData={mainData} // 검색 결과 데이터
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems} // 전체 항목 수
            itemsPerPage={itemsPerPage} // 한 페이지당 항목 수
            onPageChange={handlePageChange}
            // 재통화날짜
            follow_status={follow_status}
            setFollow_status={setFollow_status}
            // 로딩 상태
            loading={loading}
            setSelectedRecordIds={setSelectedRecordIds}
            selectedRecordIds={selectedRecordIds}
          />
        </div>
      </div>
      <div className="container_right">
        <div className="tm-filter-panel">
          <TmFilter
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            onFilterChange={handleFilterChange}
            handleSearch={handleSearch} // 검색 버튼 클릭 시 호출
            activeUsers={activeUsers}
            loginUser={loginUser}
            setSelectedRecordIds={setSelectedRecordIds}
            selectedRecordIds={selectedRecordIds}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TmMain;
