import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBox from "../../../components/TitleBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; // 한국어 로케일 추가
import "./customerDetail.scss";
import TimePicker from "./TimePicker";
import { useParams } from "react-router-dom";
import AlertModal from "../../../components/AlertModal";
import { useNavigate } from "react-router-dom";

const CustomerDetail = ({ loginUser }) => {
  console.log(loginUser.role);
  const [isChecked, setIsChecked] = useState({
    reserver_name: false,
    reserver_phone: false,
  });

  const [isTimePickerVisible, setIsTimePickerVisible] = useState({
    reservation_date: false,
    payment_date: false,
    follow_up_time: false,
  });

  const [modals, setModals] = useState({});
  const navigate = useNavigate();
  const handleOpenTimePicker = (field) => {
    setIsTimePickerVisible((prev) => ({
      ...prev,
      [field]: true, // 클릭한 필드만 true로 설정
    }));
  };
  // TimePicker 외부 클릭 시 닫기
  const handleClickOutside = (e) => {
    if (!e.target.closest(".timepickerBox")) {
      setIsTimePickerVisible(false);
    }
  };

  // 날짜 추출 함수
  const getDateFromString = (date) => {
    // console.log(date);
    // console.log(typeof date);
    if (!date) {
      return null; // date가 null인 경우
    }

    // Date 객체 또는 ISO 형식 문자열을 처리
    if (date instanceof Date) {
      // Date 객체에서 로컬 시간대 기준으로 'yyyy-mm-dd' 형식으로 날짜 반환
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    } else if (typeof date === "string") {
      // 문자열이면 'T'를 기준으로 분리하여 날짜 부분만 반환
      const datePart = date.split("T")[0];
      // 'T'가 없는 경우 입력된 문자열 그대로 반환 (올바른 날짜 형식이라고 가정)
      return datePart.includes("-") ? datePart : date; // '-' 포함 여부로 날짜 형식 검증
    }

    return date;
  };

  // 시간 추출 함수
  const getTimeFromDate = (date) => {
    console.log(typeof date);
    console.log(date);
    if (!date || typeof date !== "string" || !date.includes("T")) {
      // date가 null이면 현재 시간을 사용
      // const now = new Date();
      // const hours = now.getHours().toString().padStart(2, "0");
      // const minutes = now.getMinutes().toString().padStart(2, "0");
      // return `${hours}:${minutes}`; // 현재 시간 형식으로 반환
      return "00:00";
    }

    const timePart = date.split("T")[1]?.split(":");

    if (!timePart || timePart.length < 2) {
      return "00:00"; // 예상치 못한 형식이면 기본값 반환
    }

    const hours = timePart[0];
    const minutes = timePart[1];
    return `${hours}:${minutes}`; // 'hh:mm' 형식으로 반환
  };

  const handleDateTimeChange = (field, newTime) => {
    if (!tmData[field]) return;

    // 기존 날짜 문자열에서 시간 부분만 변경
    let currentDateStr = tmData[field]; // 예: "2024-12-20T12:00:00.000Z"

    if (!currentDateStr.includes("T")) return; // 올바른 포맷인지 확인

    const [datePart] = currentDateStr.split("T"); // "2024-12-20" 부분만 추출
    // newTime이 없거나 올바른 형식이 아닐 경우 "00:00"을 기본값으로 설정
    let newTimeFormatted = "00:00"; // 기본값

    if (typeof newTime === "string" && newTime.includes(":")) {
      newTimeFormatted = newTime; // 올바른 형식이면 그대로 사용
    }

    // 새로운 시간 문자열 조합
    const updatedISODate = `${datePart}T${newTimeFormatted}:00.000Z`;

    if (tmData[field] !== updatedISODate) {
      setTmData((prevData) => ({
        ...prevData,
        [field]: updatedISODate,
      }));
      console.log(updatedISODate);
    }
  };

  // 마운트 시 외부 클릭 감지
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const [customerId, setCustomerId] = useState(36);
  const { customerId } = useParams();

  const [customerData, setCustomerData] = useState({}); // 서버에서 조회한 데이터

  const [tmData, setTmData] = useState({
    id: null,
    call_history: null,
    call_count: null,
    reservation_date: null,
    reserver_name: null,
    reserver_phone: null,
    payment_date: null,
    visit_status: null,
    event_memo: null,
    call_memo: null,
    follow_up_time: null,
    total_payment: null,
    consultation_fee: null,
  }); // TM 데이터

  const call_history = [
    "예약",
    "부재",
    "본인아님",
    "취소요청",
    "당일재통화",
    "재통화요청",
    "본인연락",
    "중복",
    "치과전달",
    "장기부재",
    "잠재고객",
    "구환",
  ];
  const visit_status = [
    "결제",
    "상담",
    "노쇼",
    "재통화요청",
    "개인일정취소",
    "상담종료",
    "재예약",
  ];

  const call_count = [1, 2, 3, 4, 5, 6, 7];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 데이터 조회 (GET 요청)
  const fetchCustomerData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/tm-Call/${customerId}`);
      setCustomerData(response.data);
      setTmData(response.data.tm_data[0] || []); // TM 데이터 설정

      // console.log(response.data.tm_data);
    } catch (err) {
      setError("데이터를 가져오지 못했습니다.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("customerId", customerId);
    if (customerId) {
      fetchCustomerData();
    }
  }, [customerId]);

  // TM 데이터 수정 핸들러
  const handleTmDataChange = (field, value) => {
    switch (field) {
      // case "payment_date":
      // case "reservation_date":
      //   if (value.includes("null")) {
      //     setTmData((prevTmData) => ({
      //       ...prevTmData,
      //       [field]: null,
      //     }));
      //     return;
      //   }
      //   // 기존 시간이 있는 경우 유지, 없으면 00:00 기본값 설정
      //   const existingTime = tmData[field]
      //     ? getTimeFromDate(tmData[field])
      //     : "00:00";
      //   const updatedISODate = `${value}T${existingTime}:00.000Z`;

      //   setTmData((prevTmData) => ({
      //     ...prevTmData,
      //     [field]: updatedISODate, // 선택한 날짜를 그대로 반영
      //   }));
      //   break;

      case "total_payment":
      case "consultation_fee":
        const cleanedValue = value.replace(/,/g, "");
        setTmData((prevTmData) => ({
          ...prevTmData,
          [field]: cleanedValue,
        }));
        break;

      case "call_history":
        if (value === "예약") {
          setModals((prev) => ({ ...prev, reservationConfirm: true }));
        } else {
          setTmData((prevTmData) => ({
            ...prevTmData,
            [field]: value,
          }));
        }
        break;

      case "reservation_date":
      case "follow_up_time":
        const currentDate = tmData[field] ? tmData[field].split("T")[0] : null;
        const newValueDate = value.split("T")[0];

        console.log(currentDate);
        console.log(newValueDate);

        if (currentDate && currentDate === newValueDate) {
          setTmData((prevTmData) => ({
            ...prevTmData,
            [field]: null,
          }));
        } else {
          // 기존 시간 값 유지
          const existingTime = tmData[field]
            ? getTimeFromDate(tmData[field])
            : "00:00";
          const updatedISODate = `${newValueDate}T${existingTime}:00.000Z`;

          setTmData((prevTmData) => ({
            ...prevTmData,
            [field]: updatedISODate,
          }));
        }
        break;

      default:
        setTmData((prevTmData) => ({
          ...prevTmData,
          [field]: value,
        }));
        break;
    }
  };

  const handleCloseModal = (key) => {
    setModals((prev) => ({ ...prev, [key]: false }));
  };

  const toggleChecked = (field) => {
    setIsChecked((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // 데이터 PUT 요청 (TM 데이터 업데이트)
  const updateCustomerData = async () => {
    const updatedTmData = { ...tmData };
    console.log(updatedTmData);

    // 만약 isChecked가 true이면 tmData의 값을 customerData로 변경
    if (isChecked.reserver_name) {
      updatedTmData.reserver_name = customerData.name;
    }

    // console.log("1");

    if (isChecked.reserver_phone) {
      updatedTmData.reserver_phone = customerData.phone;
    }
    // console.log("2");
    //  ','가 포함되어 있다면 제거 후 숫자로 변환
    if (
      updatedTmData.total_payment &&
      updatedTmData.total_payment.includes(",")
    ) {
      updatedTmData.total_payment = updatedTmData.total_payment.replace(
        /,/g,
        ""
      );
    }
    // console.log("3");
    if (
      updatedTmData.consultation_fee &&
      updatedTmData.consultation_fee.includes(",")
    ) {
      updatedTmData.consultation_fee = updatedTmData.consultation_fee.replace(
        /,/g,
        ""
      );
    }
    // console.log("4");
    // console.log("tmData", updatedTmData);
    try {
      console.log("try");
      await axios.post(`/api/tm-Call/${customerId}`, {
        tm_data: updatedTmData,
      });
      navigate(-1); // 이전 페이지로 이동
      fetchCustomerData(); // 업데이트 후 데이터 재조회
    } catch (err) {
      setError("데이터 업데이트에 실패했습니다.");
    }
  };

  // 시간에 AM/PM을 추가하는 함수
  const formatTimeWithAMPM = (timeString) => {
    if (!timeString || !timeString.includes(":")) return "N/A";

    const [hour, minute] = timeString.split(":").map(Number);
    const period = hour < 12 ? "AM" : "PM";

    return `${timeString} ${period}`;
  };

  return (
    <div className="tmcall_container container_flex">
      <div className="container_left">
        <TitleBox mainmenu="환자별 디테일 페이지" submenu="담당자용" />

        {/* 데이터 표시 */}
        {customerData && (
          <div className="customer_data">
            <div className="inputBox">
              <div>
                <label>신청일</label>
                <input
                  style={{ cursor: "default" }}
                  value={
                    customerData.date
                      ? new Date(customerData.date).toLocaleDateString()
                      : "N/A"
                  }
                ></input>
              </div>
              <div>
                <label>지점</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.hospital_name}
                />
              </div>
              <div>
                <label>광고제목</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.ad_title}
                />
              </div>
              <div>
                <label>설문</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.customer_option}
                />
              </div>
            </div>
            <div className="inputBox">
              <div>
                <label>신청자 이름</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.name}
                />
              </div>
              <div>
                <label>예약자 이름</label>
                <input
                  value={
                    isChecked.reserver_name
                      ? customerData.name
                      : tmData.reserver_name
                  }
                  className={isChecked.reserver_name ? "" : "unchecked"}
                  onChange={(e) =>
                    handleTmDataChange("reserver_name", e.target.value)
                  }
                  disabled={isChecked.reserver_name} // 체크 여부에 따라 활성화/비활성화
                />
                <div className="same_name_checkbox">
                  <label htmlFor="same_name_checkbox">신청자와 동일</label>
                  <input
                    id="same_name_checkbox"
                    type="checkbox"
                    onChange={() => toggleChecked("reserver_name")} // 필드와 기본값을 동적으로 전달
                  />
                </div>
              </div>
              <div>
                <label>전화번호</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.phone}
                />
              </div>
              <div>
                <label>예약자 번호</label>
                <input
                  className={isChecked.reserver_phone ? "" : "unchecked"}
                  value={
                    isChecked.reserver_phone
                      ? customerData.phone
                      : tmData.reserver_phone
                  }
                  onChange={(e) =>
                    handleTmDataChange("reserver_phone", e.target.value)
                  }
                />
                <div className="same_name_checkbox">
                  <label htmlFor="">신청자와 동일</label>
                  <input
                    type="checkbox"
                    onChange={() => toggleChecked("reserver_phone")} // 필드와 기본값을 동적으로 전달
                  />
                </div>
              </div>
            </div>
            <div className="inputBox">
              <div>
                <label>매체</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.advertising_company_name}
                />
              </div>
              <div>
                <label>이벤트명</label>
                <input
                  style={{ cursor: "default" }}
                  value={customerData.event_name}
                />
              </div>
            </div>
            <div className="call_history">
              <label>콜 내역</label>
              <div className="btns">
                {call_history.map((option, index) => (
                  <button
                    key={index}
                    value={option}
                    className={
                      tmData.call_history === "예약"
                        ? option === "예약"
                          ? "check"
                          : "disabled-style"
                        : tmData.call_history === option
                        ? "check"
                        : ""
                    }
                    onClick={() => handleTmDataChange("call_history", option)}
                    disabled={tmData.call_history === "예약"}
                  >
                    {option}
                  </button>
                ))}
              </div>
              {/* 예약 확인 모달 */}
              {modals.reservationConfirm && (
                <AlertModal
                  message={
                    <>
                      콜내역에서 예약을 선택한 경우 수정이 불가능합니다.
                      <br />
                      계속 진행하시겠습니까?
                    </>
                  }
                  onConfirm={() => {
                    handleCloseModal("reservationConfirm");
                    setTmData((prev) => ({ ...prev, call_history: "예약" })); // 사용자가 확인을 누르면 상태를 업데이트
                  }}
                  onCancel={() => handleCloseModal("reservationConfirm")}
                />
              )}
            </div>

            <div className="call_count">
              <label>콜 차수</label>
              <div className="btns">
                {call_count.map((option, index) => (
                  <button
                    key={index}
                    value={option}
                    className={tmData.call_count === option ? "check" : ""}
                    onClick={() => handleTmDataChange("call_count", option)}
                  >
                    {option}차
                  </button>
                ))}
              </div>
            </div>

            <div className="reservationBox">
              {/* <div className="dateBox">
                <label>예약날짜</label>
                <button className="dateBtn">
                  날짜선택
                  <input
                    type="date"
                    value={
                      tmData.reservation_date
                        ? getDateFromString(tmData.reservation_date)
                        : ""
                    }
                    onChange={(e) =>
                      handleTmDataChange(
                        "reservation_date",
                        `${getDateFromString(e.target.value)}T00:00:00.000Z`
                      )
                    }
                  />
                </button>
                <div
                  className="timepickerBox"
                  onClick={() => handleOpenTimePicker("reservation_date")}
                >
                  <p>
                    {tmData.reservation_date &&
                      getDateFromString(tmData.reservation_date)}
                    {` `}
                    {tmData.reservation_date
                      ? formatTimeWithAMPM(
                          getTimeFromDate(tmData.reservation_date)
                        )
                      : "날짜를 선택하세요."}
                  </p>

                  {isTimePickerVisible["reservation_date"] && (
                    <TimePicker
                      currentTime={getTimeFromDate(tmData.reservation_date)}
                      field="reservation_date"
                      onTimeChange={handleDateTimeChange}
                    />
                  )}
                </div>
              </div> */}
              <div className="dateBox">
                <label>재통화날짜</label>
                <button className="dateBtn">
                  날짜선택
                  <input
                    type="date"
                    value={
                      tmData.follow_up_time
                        ? getDateFromString(tmData.follow_up_time)
                        : ""
                    }
                    onChange={(e) =>
                      handleTmDataChange(
                        "follow_up_time",
                        `${getDateFromString(e.target.value)}T00:00:00.000Z`
                      )
                    }
                  />
                </button>
                <div
                  className="timepickerBox"
                  onClick={() => handleOpenTimePicker("follow_up_time")}
                >
                  <p>
                    {tmData.follow_up_time &&
                      getDateFromString(tmData.follow_up_time)}
                    {` `}
                    {tmData.follow_up_time
                      ? formatTimeWithAMPM(
                          getTimeFromDate(tmData.follow_up_time)
                        )
                      : "날짜를 선택하세요."}
                  </p>

                  {isTimePickerVisible["follow_up_time"] && (
                    <TimePicker
                      currentTime={getTimeFromDate(tmData.follow_up_time)}
                      field="follow_up_time"
                      onTimeChange={handleDateTimeChange}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="visit_status">
              <label>방문 상태</label>
              <div className="btns">
                {visit_status.map((option) => (
                  <button
                    key={option}
                    value={option}
                    className={tmData.visit_status === option ? "check" : ""}
                    onClick={() => handleTmDataChange("visit_status", option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div className="memoBox">
              <div>
                <label>이벤트 메모</label>
                <textarea
                  placeholder="이벤트 추가 내용을 입력하세요."
                  value={tmData.event_memo ? tmData.event_memo : ""}
                  onChange={(e) => {
                    handleTmDataChange("event_memo", e.target.value);
                  }}
                />
              </div>
              <div>
                <label>콜메모</label>
                <textarea
                  placeholder="콜 내용을 입력하세요."
                  value={tmData.call_memo ? tmData.call_memo : ""}
                  onChange={(e) => {
                    handleTmDataChange("call_memo", e.target.value);
                  }}
                />
              </div>
            </div>

            {/* <div className="paymentBox">
              <div className="dateBox">
                <label>결제일</label>
                <button className="dateBtn">
                  날짜선택{" "}
                  <input
                    type="date"
                    value={
                      tmData.payment_date
                        ? getDateFromString(tmData.payment_date)
                        : ""
                    }
                    onChange={(e) =>
                      handleTmDataChange(
                        "payment_date",
                        `${getDateFromString(e.target.value)}`
                      )
                    }
                  />
                </button>
                <div className="timepickerBox">
                  <p>
                    {tmData.payment_date
                      ? getDateFromString(tmData.payment_date)
                      : "날짜를 선택하세요."}
                  </p>
                </div>
              </div>
              {loginUser.role !== 4 && (
                <div className="inputBox">
                  <label>총 결제 금액</label>
                  <input
                    type="text"
                    value={
                      tmData.total_payment && !isNaN(tmData.total_payment)
                        ? Number(tmData.total_payment).toLocaleString()
                        : ""
                    }
                    onChange={(e) => {
                      handleTmDataChange("total_payment", e.target.value);
                    }}
                    placeholder="총 결제 예정 금액을 입력하세요."
                  />
                </div>
              )}
              <div className="inputBox">
                <label>상담 금액</label>
                <input
                  type="text"
                  value={
                    tmData.consultation_fee && !isNaN(tmData.consultation_fee)
                      ? Number(tmData.consultation_fee).toLocaleString()
                      : ""
                  }
                  onChange={(e) => {
                    handleTmDataChange("consultation_fee", e.target.value);
                  }}
                  placeholder="상담 금액을 입력하세요."
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
      <div className="container_right">
        <div className="tm_data">
          <input className="manager" value={tmData.user_name} disabled />
          {/* <div className="datepickerBox">
            <div className="datepickerTitle">재통화 날짜선택</div>
            <DatePicker
              dateFormat="yyyy.MM.dd" // 날짜 형태
              shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
              minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
              // maxDate={new Date()} // maxDate 이후 날짜 선택 불가
              inline
              locale={ko}
              selected={getDateFromString(tmData.follow_up_time)}
              onChange={(date) =>
                handleTmDataChange("follow_up_time", getDateFromString(date))
              }
            />
          </div> */}
          {/* <div className="inputBox">
            <label>재통화날짜</label>
            <div
              className="timepickerBox"
              onClick={() => handleOpenTimePicker("follow_up_time")}
            >
              <p>
                {tmData.follow_up_time
                  ? `${getDateFromString(
                      tmData.follow_up_time
                    )} ${formatTimeWithAMPM(
                      getTimeFromDate(tmData.follow_up_time)
                    )}`
                  : "날짜를 선택하세요."}
              </p>
              {isTimePickerVisible["follow_up_time"] && (
                <TimePicker
                  currentTime={getTimeFromDate(tmData.follow_up_time)}
                  field="follow_up_time"
                  onTimeChange={handleDateTimeChange}
                />
              )}
            </div>
          </div> */}
          <div className="dateBox">
            <label>예약날짜</label>
            <button className="dateBtn">
              날짜선택
              <input
                type="date"
                value={
                  tmData.reservation_date
                    ? getDateFromString(tmData.reservation_date)
                    : ""
                }
                onChange={(e) =>
                  handleTmDataChange(
                    "reservation_date",
                    `${getDateFromString(e.target.value)}T00:00:00.000Z`
                  )
                }
              />
            </button>
            <div
              className="timepickerBox"
              onClick={() => handleOpenTimePicker("reservation_date")}
            >
              <p>
                {tmData.reservation_date &&
                  getDateFromString(tmData.reservation_date)}
                {` `}
                {tmData.reservation_date
                  ? formatTimeWithAMPM(getTimeFromDate(tmData.reservation_date))
                  : "날짜를 선택하세요."}
              </p>

              {isTimePickerVisible["reservation_date"] && (
                <TimePicker
                  currentTime={getTimeFromDate(tmData.reservation_date)}
                  field="reservation_date"
                  onTimeChange={handleDateTimeChange}
                />
              )}
            </div>
          </div>
          {loginUser.role !== 4 && (
            <>
              <div className="dateBox">
                <label>결제일</label>
                <button className="dateBtn">
                  날짜선택{" "}
                  <input
                    type="date"
                    value={
                      tmData.payment_date
                        ? getDateFromString(tmData.payment_date)
                        : ""
                    }
                    onChange={(e) =>
                      handleTmDataChange(
                        "payment_date",
                        `${getDateFromString(e.target.value)}`
                      )
                    }
                  />
                </button>
                <div className="timepickerBox">
                  <p>
                    {tmData.payment_date
                      ? getDateFromString(tmData.payment_date)
                      : "날짜를 선택하세요."}
                  </p>
                </div>
              </div>
              <div className="inputBox">
                <label>총 결제 금액</label>
                <input
                  type="text"
                  value={
                    tmData.total_payment && !isNaN(tmData.total_payment)
                      ? Number(tmData.total_payment).toLocaleString()
                      : ""
                  }
                  onChange={(e) => {
                    handleTmDataChange("total_payment", e.target.value);
                  }}
                  placeholder="총 결제 예정 금액을 입력하세요."
                />
              </div>
              <div className="inputBox">
                <label>상담 금액</label>
                <input
                  type="text"
                  value={
                    tmData.consultation_fee && !isNaN(tmData.consultation_fee)
                      ? Number(tmData.consultation_fee).toLocaleString()
                      : ""
                  }
                  onChange={(e) => {
                    handleTmDataChange("consultation_fee", e.target.value);
                  }}
                  placeholder="상담 금액을 입력하세요."
                />
              </div>
            </>
          )}
        </div>
        <button
          className="submitBtn"
          onClick={() =>
            setModals((prev) => ({
              ...prev,
              exitConfirm: true,
            }))
          }
        >
          상담 내용 저장
        </button>

        {modals.exitConfirm && (
          <AlertModal
            message="현재 상담 내용을 저장 후 이 페이지에서 나가시겠습니까?"
            onConfirm={updateCustomerData}
            onCancel={() => handleCloseModal("exitConfirm")}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerDetail;
