import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBox from "../../../components/TitleBox";
import Filters from "./Filters";
import "./workstatus.scss";
import { useNavigate } from "react-router-dom";
import StatusCounts from "./StatusCounts";
import Search from "./Search";
import * as XLSX from "xlsx";

const WorkStatus = ({ loginUser }) => {
  const [filteredTmList, setFilteredTmList] = useState([]);
  const itemsPerPage = 10; // 한 페이지에 표시할 항목 수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
  const [sortOrder, setSortOrder] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [searchField, setSearchField] = useState("call_history");
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    if (searchKeyword) {
      setSearchKeyword("");
    }
  }, [searchField]);

  const searchList = [
    {
      id: "call_history",
      name: "콜내역",
    },
    {
      id: "name",
      name: "신청자 이름",
    },
    {
      id: "phone",
      name: "전화번호",
    },
  ];
  const [statusCounts, setStatusCounts] = useState([]);

  const columns = [
    "콜내역",
    "지점",
    "유입날짜",
    "신청자 이름",
    "전화번호",
    "매체",
    "재통화날짜",
    "예약날짜",
    "담당자",
  ];
  const token = localStorage.getItem("token"); // 로컬 스토리지에서 토큰 가져오기

  const handleFilterChange = async (page = currentPage) => {
    try {
      const response = await axios.get("/api/tm-workstatus/list", {
        headers: {
          Authorization: `Bearer ${token}`, // 헤더에 토큰 추가
        },
        params: {
          hospital_id:
            loginUser.role === 5
              ? loginUser.hospital_name_id
              : selectedHospital, // 선택된 병원 ID
          manager: loginUser.role === 4 ? loginUser.user_id : selectedManager, // 선택된 담당자
          date: {
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          }, // 날짜 필드 포함
          searchField: searchField,
          searchKeyword: searchKeyword,
          page: page, // 현재 페이지 번호
          itemsPerPage: itemsPerPage, // 백엔드에서 5개씩 가져오도록 요청
          sortOrder: sortOrder, // 정렬기준
        },
      });

      const fetchedData = response.data.tmWithDetails || [];
      setFilteredTmList(fetchedData);
      setStatusCounts({
        absenceCount: response.data.absenceCount,
        cancellationsCount: response.data.cancellationsCount,
        reservationCount: response.data.reservationCount,
        todayEmptyMemosCount: response.data.todayEmptyMemosCount,
        totalItems: response.data.totalItems,
      });
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("필터된 데이터 로딩 오류:", error);
    }
  };

  useEffect(() => {
    handleFilterChange(1); // ✅ 필터 변경 시 1페이지로 이동
  }, [selectedHospital, selectedManager, selectedDate, searchField, sortOrder]);

  useEffect(() => {
    handleFilterChange(currentPage); // ✅ 단순 페이지 이동 시 유지
  }, [currentPage]);

  const formatDate = (isoString) => {
    if (isoString) {
      return isoString.slice(0, 16).replace("T", " ");
    }
  };

  const getClassForCallHistory = (callHistory, isNewStatus) => {
    if (isNewStatus) return "blue-status";
    if (["노쇼", "취소요청"].includes(callHistory)) return "red-status"; // 빨간색
    if (callHistory === "예약") return "yellow-status"; // 노란색

    return " "; // 기본값 (스타일 없음)
  };

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/tm/CustomerDetail/${id}`);
  };

  const handleExcelDown = async () => {
    try {
      // 1. API 호출하여 데이터 가져오기
      const response = await axios.get("/api/tm-workstatus/list/excel", {
        headers: {
          Authorization: `Bearer ${token}`, // 헤더에 토큰 추가
        },
        params: {
          hospital_id: selectedHospital, // 선택된 병원 ID
          manager: selectedManager, // 선택된 담당자
          date: {
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          }, // 날짜 필드 포함
          searchField: searchField,
          searchKeyword: searchKeyword,
        },
      });

      const tmExcelList = response.data.tmWithDetails;

      // 2. 데이터가 없는 경우 경고창 표시
      if (!tmExcelList.length) {
        alert("다운로드할 데이터가 없습니다.");
        return;
      }

      // 3. 데이터를 엑셀 형식으로 변환
      const excelData = tmExcelList.map((tm) => ({
        콜내역: tm.isNewStatus ? "신규" : tm.call_history,
        지점: tm.hospital_name,
        유입날짜: formatDate(tm.date),
        신청자이름: tm.name || "-",
        전화번호: tm.phone || "-",
        매체: tm.advertising_company_name,
        재통화날짜: formatDate(tm.follow_up_time) || "-",
        예약날짜: formatDate(tm.reservation_date) || "-",
        담당자: tm.manager,
      }));

      // 4. 워크시트 및 워크북 생성
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "업무 현황");

      // 5. 엑셀 파일 저장
      XLSX.writeFile(
        workbook,
        `업무_현황_${new Date().toISOString().slice(0, 10)}.xlsx`
      );
    } catch (error) {
      console.error("엑셀 다운로드 오류:", error);
      alert("엑셀 다운로드 중 오류가 발생했습니다.");
    }
  };

  // ----------------------------------- 페이지 네이션
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // 페이지 변경
    }
  };

  const handleSortChange = () => {
    if (sortOrder === null) {
      setSortOrder("desc"); // ✅ 첫 번째 클릭 → follow_up_time 내림차순
    } else if (sortOrder === "desc") {
      setSortOrder("asc"); // ✅ 두 번째 클릭 → follow_up_time 오름차순
    } else {
      setSortOrder(null); // ✅ 세 번째 클릭 → 기본 정렬 (customer_db.date desc)
    }
  };

  return (
    <div className="tmWorkStatus_container container">
      <TitleBox mainmenu="TM" submenu="담당자 업무 현황" />
      <Filters
        hospitalList={hospitalList}
        setHospitalList={setHospitalList}
        selectedHospital={selectedHospital} // 선택된 병원원
        setSelectedHospital={setSelectedHospital}
        selectedManager={selectedManager} // 선택된 담당자
        setSelectedManager={setSelectedManager}
        managerList={managerList}
        setManagerList={setManagerList}
        handleFilterChange={handleFilterChange}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        role={loginUser.role}
        loginUser={loginUser}
      />
      <StatusCounts statusCounts={statusCounts} />
      <Search
        searchList={searchList}
        searchField={searchField}
        setSearchField={setSearchField}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        handleFilterChange={handleFilterChange}
      />
      <button className="excel-button" onClick={handleExcelDown}>
        <img
          src={process.env.PUBLIC_URL + "/images/excel.png"}
          alt="엑셀다운로드"
          className="excel-icon"
        />
      </button>

      <table className="tm_table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={column === "재통화날짜" && "follow_up_time"}
              >
                {column}{" "}
                {column === "재통화날짜" && (
                  <button className="sortBtn" onClick={handleSortChange}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sort.png`}
                      alt="재통화날짜 정렬"
                    />
                  </button>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredTmList &&
            filteredTmList.map((tm, index) => (
              <tr
                key={index}
                onDoubleClick={() => handleRowClick(tm.customer_id)}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <span
                    className={`default-status ${getClassForCallHistory(
                      tm.call_history,
                      tm.isNewStatus
                    )}
                `}
                  >
                    {tm.isNewStatus ? "NEW" : tm.call_history || "-"}
                  </span>
                </td>
                <td>{tm.hospital_name}</td>
                <td>{formatDate(tm.date)}</td>
                <td>{tm.name}</td>
                <td>{tm.phone}</td>
                <td>{tm.advertising_company_name}</td>
                <td>{formatDate(tm.follow_up_time)} </td>
                <td>{formatDate(tm.reservation_date)} </td>
                <td>{tm.manager}</td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* 페이지네이션 */}
      <div className="pagination">
        <button onClick={() => handlePageChange(1)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/start.png"}
            className="doubleArrow"
            alt="첫 페이지"
          />
        </button>
        {/* 이전 페이지 */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/before.png"}
            className="singleArrow"
            alt="이전 페이지"
          />
        </button>
        {Array.from({ length: totalPages }, (_, index) => index + 1)
          .slice(
            (Math.ceil(currentPage / 10) - 1) * 10,
            Math.ceil(currentPage / 10) * 10
          )
          .map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={page === currentPage ? "currentPage" : ""}
            >
              {page}
            </button>
          ))}
        <button onClick={() => handlePageChange(currentPage + 10)}>
          {"..."}
        </button>{" "}
        {/* 다음 페이지 묶음 */}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/page/end.png"}
            className="singleArrow"
            alt="다음 페이지"
          />
        </button>
        {/* 마지막 페이지 */}
        <button onClick={() => handlePageChange(totalPages)}>
          <img
            src={process.env.PUBLIC_URL + "/images/page/next.png"}
            className="doubleArrow"
            alt="마지막 페이지"
          />
        </button>
      </div>
    </div>
  );
};

export default WorkStatus;
