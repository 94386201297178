import React, { useState, useEffect, useRef } from "react";

function CustomDropdown({
  selectedValue, // 선택된 값 (배열 또는 단일 값)
  options, // 드롭다운 옵션
  onChange, // 값 변경 시 호출되는 함수
  bigDrop, // 큰 드롭다운 여부
  search, // 검색 활성화 여부
  optionChecked, // 체크박스 사용 여부
  ischeckedopen, // 체크박스 선택 시 드롭다운 유지 여부
}) {
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 열림 상태
  const [checkedOptions, setCheckedOptions] = useState([]); // 다중 선택 상태
  const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
  const [selectedOption, setSelectedOption] = useState(""); // 단일 선택 상태
  const dropdownRef = useRef(null); // 🔹 드롭다운 요소 참조

  // **초기 선택 상태 설정**
  useEffect(() => {
    if (optionChecked) {
      const normalizedSelectedValue = Array.isArray(selectedValue)
        ? selectedValue.map((val) => Number(val)) // 문자열 → 숫자 변환
        : [];
      setCheckedOptions(normalizedSelectedValue); // 숫자 배열로 설정
    } else {
      const currentOption = options.find(
        (option) => option.value === Number(selectedValue) // 타입 일치
      );
      if (currentOption) {
        setSelectedOption(currentOption.label);
      }
    }
  }, [selectedValue, options, optionChecked]);

  // **외부 클릭 감지 핸들러**
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // **체크박스 상태 변경 핸들러**
  const handleCheckboxChange = (value) => {
    let updatedCheckedOptions;
    if (checkedOptions.includes(value)) {
      updatedCheckedOptions = checkedOptions.filter((item) => item !== value);
    } else {
      updatedCheckedOptions = [...checkedOptions, value];
    }
    setCheckedOptions(updatedCheckedOptions);
    onChange(updatedCheckedOptions); // 숫자 배열 그대로 전달
  };

  // **단일 선택 핸들러**
  const handleOptionClick = (option) => {
    if (!optionChecked) {
      setSelectedOption(option.label); // 단일 선택 업데이트
      onChange(String(option.value)); // 상위 컴포넌트로 전달
      setIsOpen(false); // 드롭다운 닫기
    }
  };

  // **옵션 필터링 (검색)**
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // **헤더 텍스트 결정**
  const headerText = optionChecked
    ? checkedOptions.length > 0
      ? options
          .filter((option) => checkedOptions.includes(option.value))
          .map((option) => option.label)
          .join(", ") // 선택된 옵션 표시
      : "선택"
    : selectedOption || "선택";

  return (
    <div
      className={`dropdown ${bigDrop === 1 ? "big-dropdown" : ""}`}
      ref={dropdownRef}
    >
      {/* 드롭다운 헤더 */}
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {headerText}
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}></span>
      </div>

      {/* 드롭다운 옵션 */}
      {isOpen && (
        <div className="dropdown-options">
          {search === 1 && (
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="검색"
              className="dropdown-search"
            />
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`${
                  bigDrop === 1 ? "dropdown-option-set" : "dropdown-option"
                } ${optionChecked ? "ellipsis" : ""}`}
                onClick={
                  optionChecked
                    ? () => handleCheckboxChange(option.value)
                    : () => handleOptionClick(option)
                }
                style={optionChecked ? { textAlign: "left" } : {}}
              >
                {optionChecked && (
                  <input
                    style={
                      !ischeckedopen // 프롭스를 확인
                        ? {
                            height: "12px",
                            width: "12px",
                          }
                        : undefined
                    }
                    className="dropdownCheckBox"
                    type="checkbox"
                    checked={checkedOptions.includes(option.value)} // 선택 여부 반영
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                )}
                {option.label}
              </div>
            ))
          ) : (
            <div className="no-options">옵션이 없습니다.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
