import axios from "axios";
import TitleBox from "../../components/TitleBox";
import "./advertisingExpenses.scss";
import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";

function AdExpenseStatus() {
  // 날짜 테이블-----------------------------------------
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // 현재 연도 기본값
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // 현재 월 (0부터 시작하므로 +1)
  const [daysInMonth, setDaysInMonth] = useState(0); // 선택된 달의 총 일수
  const [previousDate, setPreviousDate] = useState(new Date().getDate() - 1);
  // 소진현황 테이블----------------------------------------
  const [adExpenseStatusRows, setAdExpenseStatusRows] = useState([
    { hospitalName: "병원A", dbAdBudget: 55000000, ad_spend: 50005164 },
    { hospitalName: "병원B", dbAdBudget: 2000000, ad_spend: 1000000 },
    // 다른 병원 데이터...
  ]);
  const adExpenseStatusHeader = [
    "병원명",
    " DB 목표 예산",
    "소진 예산",
    "남은 예산",
    "현재 소진율",
    "목표 대비",
    "소진 필요 일일 광고비",
  ];

  // 날짜 테이블-----------------------------------------
  // const handleMonthChange = (e) => {
  //   const [year, month] = e.target.value.split("-"); // month input에서 'YYYY-MM' 형식으로 받아옴
  //   setSelectedYear(Number(year)); // 연도 업데이트
  //   setSelectedMonth(Number(month)); // 월 업데이트
  // };

  const handleMonthChange = (direction) => {
    // 현재 선택된 연도와 월을 기준으로 계산
    let newYear = selectedYear;
    let newMonth = selectedMonth;

    if (direction === "prev") {
      // 이전 달로 이동
      if (newMonth === 1) {
        newYear -= 1; // 1월이면 작년 12월로 이동
        newMonth = 12;
      } else {
        newMonth -= 1;
      }
    } else if (direction === "next") {
      // 다음 달로 이동
      if (newMonth === 12) {
        newYear += 1; // 12월이면 내년 1월로 이동
        newMonth = 1;
      } else {
        newMonth += 1;
      }
    }

    // 연도와 월 업데이트
    setSelectedYear(newYear);
    setSelectedMonth(newMonth);
  };

  // 선택된 달에 맞는 총 일수 계산 함수
  const calculateDaysInMonth = (year, month) => {
    // month는 1부터 시작하므로 0-based 월로 변환
    const lastDayOfMonth = new Date(year, month, 0); // 마지막 날
    return lastDayOfMonth.getDate(); // 마지막 날짜를 반환 (총 일수)
  };

  // 광고비 소진현황 데이터 받아오기
  useEffect(() => {
    // 달이 변경될 때마다 총 일수 업데이트
    const days = calculateDaysInMonth(selectedYear, selectedMonth);
    setDaysInMonth(days); // 상태 업데이트
    const fetchFinances = async () => {
      try {
        const response = await axios.get(`/api/adexpense/hospital-finances`, {
          params: {
            year: selectedYear,
            month: selectedMonth,
          },
        });
        // console.log("Fetched data:", response.data); // 데이터 구조 확인
        setAdExpenseStatusRows(response.data);
      } catch (error) {
        console.error("Error fetching finances:", error);
      }
    };

    fetchFinances();
  }, [selectedYear, selectedMonth]);

  // 계산된 데이터를 미리 준비
  const processedRows = adExpenseStatusRows.map((row) => {
    // 남은 예산 (목표 예산 - 소진 예산)
    const remainingBudget = row.dbAdBudget - row.ad_spend;
    // 소진율 (소진 예산 / 목표 예산) * 100
    const spentPercent = (row.ad_spend / row.dbAdBudget) * 100;
    // 목표대비율 계산 (소진 예산 / 목표 예산) - 1
    const targetComparison = (row.ad_spend / row.dbAdBudget - 1) * 100;
    // 소진 필요 일일 광고비 (남은 예산 / 남은 일수)
    const dailyAdExpense = Math.floor(
      remainingBudget / (daysInMonth - previousDate)
    );

    return {
      ...row,
      remainingBudget, // 계산된 남은 예산 추가
      spentPercent, // 계산된 소진율 추가
      targetComparison, // 계산된 목표대비율 추가
      dailyAdExpense, // 계산된 소진 필요 일일 광고비 추가
    };
  });

  // 합계 계산
  const totals = processedRows.reduce(
    (acc, row) => ({
      dbAdBudget: acc.dbAdBudget + row.dbAdBudget,
      ad_spend: acc.ad_spend + row.ad_spend,
      remainingBudget: acc.remainingBudget + row.remainingBudget,
      spentPercent: acc.spentPercent + row.spentPercent / processedRows.length, // 평균
      targetComparison:
        acc.targetComparison + row.targetComparison / processedRows.length, // 평균
      dailyAdExpense: acc.dailyAdExpense + row.dailyAdExpense,
    }),
    {
      dbAdBudget: 0,
      ad_spend: 0,
      remainingBudget: 0,
      spentPercent: 0,
      targetComparison: 0,
      dailyAdExpense: 0,
    }
  );

  // 인쇄설정------------------------------------------------------------

  const contentRef = useRef(null); // 인쇄할 컴포넌트를 참조하는 ref 생성
  const [pdfReady, setPdfReady] = useState(false); // Content 준비 상태 확인

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `${selectedYear}년${selectedYear}월_광고비_소진현황`,
  });

  // PDF 저장 기능
  const handleSaveAsPDF = () => {
    const element = document.getElementById("adExpenseStatusPdf");
    html2pdf(element, {
      filename: `${selectedYear}년${selectedYear}월_광고비_소진현황`, // PDF 파일명 설정
      html2canvas: { scale: 4 }, // 고해상도 PDF
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // PDF 설정
    });
  };

  const SaveAsPDFready = () => {
    setPdfReady(true);
  };

  useEffect(() => {
    if (pdfReady) {
      handleSaveAsPDF();
      setPdfReady(false); // PDF 생성 후 상태를 다시 false로 설정
    }
  }, [pdfReady]); // pdfReady가 변경될 때마다 실행

  // 주간 소진 현황-----------------------------------------

  const [isWeeklyUsageModalOpen, setIsWeeklyUsageModalOpen] = useState(false);
  const [weeklyAdSpending, setWeeklyAdSpending] = useState([
    { week: "1", dateRange: "1 ~ 2", spentBudget: "150,000" },
    { week: "2", dateRange: "3 ~ 9", spentBudget: "700,000" },
    { week: "3", dateRange: "10 ~ 16", spentBudget: "600,000" },
    { week: "4", dateRange: "17 ~ 23", spentBudget: "550,000" },
    { week: "5", dateRange: "24 ~ 30", spentBudget: "300,000" },
  ]);

  const fetchWeeklyAdSpending = async () => {
    try {
      const response = await axios.get(`/api/adexpense/weekly-spending`, {
        params: {
          year: selectedYear, // 선택된 연도
          month: selectedMonth, // 선택된 월
        },
      });
      console.log("Fetched weekly ad spending data:", response.data);
      setWeeklyAdSpending(response.data); // 받은 데이터를 상태로 업데이트
    } catch (error) {
      console.error("Error fetching weekly ad spending data:", error);
    }
  };

  const toggleWeeklyUsageModal = () => {
    fetchWeeklyAdSpending();
    setIsWeeklyUsageModalOpen((prev) => !prev);
  };

  return (
    <div className="adExpenseStatus container_flex">
      <div className="container_left">
        <TitleBox mainmenu="광고비관리" submenu="광고비 소진현황" />
        <div
          ref={contentRef}
          id={pdfReady ? "adExpenseStatusPdf" : ""}
          className="adExpenseStatusPrint"
        >
          <div className="dateTitleBox">
            <button
              className="monthButton no-print"
              onClick={() => handleMonthChange("prev")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
              >
                <path d="M8 13L2 7L8 1" stroke="#979797" stroke-width="2" />
              </svg>
            </button>
            <h3 className="printTitle">
              {selectedYear}년 {selectedMonth}월 광고비 소진현황
            </h3>
            <button
              className="monthButton no-print"
              onClick={() => handleMonthChange("next")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
              >
                <path
                  d="M1 0.999999L7 7L0.999999 13"
                  stroke="#979797"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
          {/* <input
            className="no-print"
            type="month"
            value={`${selectedYear}-${
              selectedMonth < 10 ? "0" + selectedMonth : selectedMonth
            }`} // "YYYY-MM" 형식으로 초기값 설정
            onChange={handleMonthChange}
          /> */}

          {selectedYear == new Date().getFullYear() &&
            selectedMonth == new Date().getMonth() + 1 && (
              <table className="dateTable">
                <thead>
                  <tr>
                    <th>조회날짜(TODAY)</th>
                    <th>{selectedMonth}월 총 일수</th>
                    <th>진행일수(전일 기준)</th>
                    <th>남은 일수</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{new Date().toLocaleDateString()}</td>
                    <td>{daysInMonth}</td>
                    <td>{previousDate}</td>
                    <td>{daysInMonth - previousDate}</td>
                  </tr>
                </tbody>
              </table>
            )}
          <table className="adExpenseStatusTable">
            <thead>
              <tr>
                {adExpenseStatusHeader.map((header, index) => {
                  return <th key={index}>{header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {processedRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.hospitalName}</td> {/* 병원명 */}
                  <td>₩ {row.dbAdBudget.toLocaleString()}</td> {/* 목표 예산 */}
                  <td>₩ {row.ad_spend.toLocaleString()}</td> {/* 소진 예산 */}
                  <td>₩ {row.remainingBudget.toLocaleString()}</td>
                  {/* 남은 예산 */}
                  <td
                    style={{
                      color: row.spentPercent >= 0 ? "red" : "blue",
                    }}
                  >
                    {row.spentPercent.toFixed(2)}%
                  </td>{" "}
                  {/* 현재 소진율 */}
                  <td
                    style={{
                      color: row.targetComparison >= 0 ? "red" : "blue",
                    }}
                  >
                    {row.targetComparison.toFixed(2)}%
                  </td>
                  {/* 목표 대비*/}
                  <td>₩ {row.dailyAdExpense.toLocaleString()}</td>
                  {/* 소진 필요 일일 광고비 */}
                </tr>
              ))}
              <tr className="totalRow">
                <td>합계</td>
                <td>₩ {totals.dbAdBudget.toLocaleString()}</td>
                <td>₩ {totals.ad_spend.toLocaleString()}</td>
                <td>₩ {totals.remainingBudget.toLocaleString()}</td>
                <td
                  style={{ color: totals.spentPercent >= 0 ? "red" : "blue" }}
                >
                  {totals.spentPercent.toFixed(2)}%
                </td>
                <td
                  style={{
                    color: totals.targetComparison >= 0 ? "red" : "blue",
                  }}
                >
                  {totals.targetComparison.toFixed(2)}%
                </td>
                <td>
                  {totals.spentPercent >= 0 && totals.targetComparison >= 0
                    ? "-"
                    : `₩ ${totals.dailyAdExpense.toLocaleString()}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="container_right button-group">
        <button className="printBtn" onClick={handlePrint}>
          <img
            src={process.env.PUBLIC_URL + "/images/print.png"}
            alt="인쇄하기"
          />
          인쇄하기
        </button>
        <button className="pdfBtn" onClick={SaveAsPDFready}>
          <img
            src={process.env.PUBLIC_URL + "/images/pdf.png"}
            alt="PDF로 저장"
          />
          PDF로 저장
        </button>
        <button className="weeklyUsageButton" onClick={toggleWeeklyUsageModal}>
          해당 월 주차별 소진현황 조회
        </button>
      </div>
      {isWeeklyUsageModalOpen && (
        <div className="modalOverlay">
          <div className="weeklyUsageModal">
            <h4>
              {selectedYear}년 {selectedMonth}월 주차별 소진현황
            </h4>
            <table>
              <thead>
                <tr>
                  <th>광고비 지출</th>
                  <th>소진 예산</th>
                </tr>
              </thead>
              <tbody>
                {weeklyAdSpending &&
                  weeklyAdSpending.map((weeklyData, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {weeklyData.week}주차 ({weeklyData.dateRange})
                        </td>
                        <td>₩ {weeklyData.spentBudget.toLocaleString()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <p className="vat">*gdn, 메타, 틱톡: vat 미포함</p>
            <button className="closeBtn" onClick={toggleWeeklyUsageModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path d="M1 1L13 13M13 1L1 13" stroke="#919191" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default AdExpenseStatus;
