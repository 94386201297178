import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dataInputModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ConfirmSaveModal from "../../../components/ConfirmSaveModal";
import { shiftDateRange } from "../../../utils/shiftdaterange";
const DataInputModal = ({ isOpen, onClose }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // 월은 0부터 시작하므로 +1
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [name, setName] = useState(null);
  const [landingCode, setLandingCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const today = new Date();
  const canSave = name && landingCode && phone && selectedDate;
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  // 달력 생성 함수
  const generateCalendar = () => {
    const firstDayOfMonth = new Date(year, month - 1, 1); // 월은 0부터 시작
    const lastDayOfMonth = new Date(year, month, 0); // 다음 달의 0일은 현재 달의 마지막 날
    const calendarDays = [];
    const startDay = firstDayOfMonth.getDay(); // 해당 월의 시작 요일 (0: 일요일)
    const totalDays = lastDayOfMonth.getDate(); // 해당 월의 총 일수

    // 이전 달 공백 채우기
    for (let i = 0; i < startDay; i++) {
      calendarDays.push(null);
    }

    // 해당 월의 날짜 추가
    for (let day = 1; day <= totalDays; day++) {
      calendarDays.push(day);
    }

    return calendarDays;
  };

  const calendarDays = generateCalendar();

  const handleMonthChange = (direction) => {
    let newMonth = month + direction;
    let newYear = year;

    if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    } else if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    }

    setYear(newYear);
    setMonth(newMonth);
    setSelectedDate(null);
  };
  const formatDate = (date) => {
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDateClick = (day) => {
    console.log("수동기입날짜", day);
    if (day) {
      setSelectedDate(
        new Date(
          year,
          month - 1,
          day,
          today.getHours(),
          today.getMinutes(),
          today.getSeconds()
        )
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!canSave) return;
    console.log(selectedDate, name, landingCode, phone);
    try {
      const response = await axios.post("api/tm-Main/simpledb", {
        selectedDate,
        name,
        landingCode,
        phone,
      });
      console.log("서버 응답:", response.data);
      setModalOpen(true);
    } catch (error) {
      console.error("에러 발생:", error);
      alert("데이터 전송에 실패했습니다.");
    }
    onClose();
  };

  // const shiftDateRangeHandler = (direction) => {
  //   const { startDate, endDate } = shiftDateRange(
  //     customDateRange.startDate,
  //     customDateRange.endDate,
  //     direction
  //   );
  //   setCustomDateRange({
  //     startDate,
  //     endDate,
  //   });
  // };

  if (!isOpen) return null;
  return (
    <div className="dataInputModal">
      <div className="modal-content">
        <div className="calendar-filters">
          <div className="calendar-title">
            <h3>날짜선택</h3>
            <span className="year-month">{`${year}년 ${month
              .toString()
              .padStart(2, "0")}월`}</span>
          </div>
          <div className="calendar">
            <div className="calendar-header">
              {["일", "월", "화", "수", "목", "금", "토"].map((day, index) => (
                <div key={day} className={"calendar-day"}>
                  {day}
                </div>
              ))}
            </div>

            <div className="calendar-grid">
              {calendarDays.map((day, index) => (
                <div
                  key={index}
                  className={`calendar-cell ${
                    day === today.getDate() &&
                    today.getMonth() + 1 === month &&
                    today.getFullYear() === year
                      ? "today"
                      : ""
                  }  ${
                    selectedDate &&
                    day === selectedDate.getDate() &&
                    selectedDate.getMonth() + 1 === month &&
                    selectedDate.getFullYear() === year
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleDateClick(day)}
                >
                  {day && (
                    <>
                      <div className="calendar-date">{day}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="year-month-button">
            <button className="month-btn" onClick={() => handleMonthChange(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <button className="month-btn" onClick={() => handleMonthChange(1)}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <div className="dataInput">
          <h3>DB 수동기입 입력</h3>

          <div className="input-group">
            <input
              type="text"
              placeholder="이름"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="랜딩코드"
              value={landingCode}
              onChange={(e) => setLandingCode(e.target.value)}
            />
            <input
              type="number"
              placeholder="전화번호"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="cancle-save-btns">
            <button className="cancleBtn" onClick={onClose}>
              취소
            </button>
            <button
              className={canSave ? "saveBtn active" : "saveBtn"}
              onClick={handleSubmit}
              disabled={!canSave}
            >
              저장
            </button>
            {modalOpen && (
              <ConfirmSaveModal
                message="정상적으로 저장되었습니다"
                onConfirm={() => setModalOpen(false)}
              />
            )}
          </div>
        </div>
        {loading && <p className="calendar-loading">Loading...</p>}
        {error && <p className="calendar-error-message">{error}</p>}
      </div>
    </div>
  );
};

export default DataInputModal;
