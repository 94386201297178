import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; // 한국어 로케일 추가

const DateRangePicker = ({
  customDateRange,
  setCustomDateRange,
  closePicker,
  setSelectedDateOption,
}) => {
  // 상태를 추가하여 시작과 종료 날짜를 관리
  const [dateRange, setDateRange] = useState([
    new Date(customDateRange.startDate),
    new Date(customDateRange.endDate),
  ]);

  // 부모 컴포넌트에서 날짜 범위가 변경될 때 갱신
  useEffect(() => {
    setDateRange([
      new Date(customDateRange.startDate),
      new Date(customDateRange.endDate),
    ]);
  }, [customDateRange.startDate, customDateRange.endDate]);

  // DateRangePicker 변경 사항 반영
  const handleChange = (update) => {
    setDateRange(update);
    if (update[0] && update[1]) {
      setCustomDateRange({
        startDate: update[0],
        endDate: update[1],
      });
      closePicker();
    }
  };

  return (
    <DatePicker
      selectsRange={true} // 범위 선택 활성화
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      onChange={handleChange}
      dateFormat="yyyy/MM/dd"
      maxDate={new Date()}
      inline
      locale={ko}
    />
  );
};

export default DateRangePicker;
