import React, { useEffect } from "react";

import SideMenu from "./SideMenu";
import Header from "./Header";
import "./layout.scss";
import { useLocation, useNavigate } from "react-router-dom";

function Layout({ children, name, role }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // 로그인 상태를 확인하고, role이 null이면 로그인 페이지로 리디렉트
    if (role === null) {
      navigate("/login");
    }
  }, [role, navigate]); // loginUser.role이 변경될 때마다 효과 실행

  const isTaxPage = location.pathname === "/tax";

  return (
    <div className="layout">
      {!isTaxPage && <Header name={name} role={role} />}
      <div className="main">
        {!isTaxPage && <SideMenu role={role} />}
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
